import React, { Fragment, Suspense } from 'react';

import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import EmptyState from '@atlaskit/empty-state';
import Blog16Icon from '@atlaskit/icon-object/glyph/blog/16';
import Page16Icon from '@atlaskit/icon-object/glyph/page/16';
import OpenFolderIcon from '@atlaskit/icon/core/folder-open';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import FileIcon from '@atlaskit/icon/core/migration/file';
import OfficeBuildingIcon from '@atlaskit/icon/core/migration/office-building';
import DocumentsIcon from '@atlaskit/icon/core/migration/pages--documents';
import QuoteIcon from '@atlaskit/icon/core/migration/quotation-mark--quote';
import { OpenFolderIcon as LegacyOpenFolderIcon } from '@atlaskit/legacy-custom-icons';
import Link from '@atlaskit/link';
import Modal, { ModalBody, ModalFooter, ModalTitle } from '@atlaskit/modal-dialog';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import TimeAgo from '@atlassian/mpt-timeago';

import { useAIAnswerContext } from '../../../../ai-answer-dialog/ai-answer-context';
import { type CuratedDefinitionScopeConfigType, EditedDefinitionScope } from '../types';

import { messages } from './messages';
import { useDefinitionEditHistory } from './useDefinitionEditHistory';

const MarkdownRenderer = React.lazy(() =>
	import(/* webpackChunkName: "react-markdown" */ 'react-markdown').then(
		({ default: ReactMarkdown }) => {
			return {
				default: ReactMarkdown,
			};
		},
	),
);

const loadingSpinnerStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	height: '100%',
	width: '100%',
});

const editHistoryModalStyles = xcss({
	display: 'flex',
	paddingTop: 'space.300',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	paddingBottom: 'space.100',
	justifyContent: 'space-between',
	alignItems: 'center',
	position: 'relative',
});

const editHistoryWrapperStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	paddingBottom: 'space.250',
	gap: 'space.200',
	font: token('font.body'),
});

const editHistoryDescriptionStyles = xcss({
	color: 'color.text',
	gap: 'space.050',
});

const editedDefinitionCardContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.100',
});

const editedDefinitionScopeTextStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	color: 'color.text.subtlest',
});

const editedDefinitionCardStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	padding: 'space.200',
	borderRadius: token('space.100', '8px'),
	border: `1px solid ${token('color.border', '#091E4224')}`,
	gap: 'space.200',
});

const editedDefinitionAvatarStyles = xcss({
	borderRadius: '64%',
});

const editedDefinitionCardBodyStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	color: 'color.text',
	font: token('font.body'),
	gap: 'space.150',
});

const editedDefinitionCardHeaderWrapperStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.0',
});

const editedDefinitionCardHeaderSpacerStyles = {
	width: '100%',
	color: token('color.border', '#F0F0F3'),
	marginTop: 3,
	marginBottom: 0,
};

const editedDefinitionCardHeaderStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: 'space.050',
	color: 'color.text.subtle',
	marginBottom: 'space.100',
});

const editedDefinitionCardEditorStyles = xcss({
	fontWeight: token('font.weight.semibold'),
	color: 'color.text',
});

const editedDefinitionCardReferenceStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});

const editedDefinitionCardReferenceTitleStyles = xcss({
	marginLeft: 'space.050',
});

const ScopeDescription = ({ scope }: { scope: EditedDefinitionScope }) => {
	const { formatMessage } = useIntl();
	let scopeDescription = '';
	let ScopeIcon;
	switch (scope) {
		case EditedDefinitionScope.ORGANIZATION:
			scopeDescription = formatMessage(messages.readingAidsEditHistoryVisibilityTextOrg);
			ScopeIcon = OfficeBuildingIcon;
			break;
		case EditedDefinitionScope.SPACE:
			scopeDescription = formatMessage(messages.readingAidsEditHistoryVisibilitySpace);
			ScopeIcon = DocumentsIcon;
			break;
		case EditedDefinitionScope.PAGE:
			scopeDescription = formatMessage(messages.readingAidsEditHistoryVisibilityPage);
			ScopeIcon = FileIcon;
			break;
		case EditedDefinitionScope.BLOGPOST:
			scopeDescription = formatMessage(messages.readingAidsEditHistoryVisibilityBlog);
			ScopeIcon = QuoteIcon;
			break;
		case EditedDefinitionScope.PROJECT:
			scopeDescription = formatMessage(messages.readingAidsEditHistoryVisibilityProject);
			ScopeIcon = () => (
				<OpenFolderIcon
					color={token('color.icon')}
					label=""
					LEGACY_fallbackIcon={LegacyOpenFolderIcon}
					LEGACY_size="medium"
					LEGACY_margin={`0 0 0 ${token('space.negative.050')}`}
				/>
			);
			break;
		default:
			return null;
	}
	return (
		<Inline xcss={editedDefinitionScopeTextStyles} space="space.050">
			{scopeDescription} <ScopeIcon label="" />
		</Inline>
	);
};

type DefinitionEditHistoryProps = {
	curatedDefinitionScopeConfig: CuratedDefinitionScopeConfigType;
	onClose: () => void;
};

export const DefinitionEditHistory = ({
	curatedDefinitionScopeConfig,
	onClose,
}: DefinitionEditHistoryProps) => {
	const { formatMessage } = useIntl();
	const {
		commonAttributes: { query },
	} = useAIAnswerContext();
	const { definitions, loading } = useDefinitionEditHistory({
		query,
		curatedDefinitionScopeConfig,
	});
	const hasError = definitions.length === 0 && !loading;

	return (
		<Modal onClose={onClose} height={600}>
			<Box xcss={editHistoryModalStyles}>
				<ModalTitle>{formatMessage(messages.readingAidsEditHistoryHeader)}</ModalTitle>
				<IconButton appearance="subtle" icon={CrossIcon} label="" onClick={onClose} />
			</Box>
			<ModalBody>
				<Stack xcss={editHistoryWrapperStyles}>
					<Inline xcss={editHistoryDescriptionStyles}>
						{formatMessage(messages.readingAidsEditHistoryDescription, {
							queryText: <b>{query}</b>,
						})}
					</Inline>
					{hasError || loading ? (
						<Box xcss={loadingSpinnerStyles}>
							{loading && <Spinner size="large" testId="definition-history-loading-spinner" />}
							{hasError && (
								<EmptyState header={formatMessage(messages.readingAidsEditHistoryCardErrorText)} />
							)}
						</Box>
					) : (
						definitions.map((item, index) => (
							<Box
								xcss={editedDefinitionCardContainerStyles}
								key={`edited-definition-history-card-${index}`}
							>
								<ScopeDescription scope={item.scope} />
								<Box xcss={editedDefinitionCardStyles}>
									<Box xcss={editedDefinitionAvatarStyles}>
										{/* eslint-disable-next-line jsx-a11y/alt-text */}
										<img src={item.editor?.picture || ''} width="32px" height="32px" />
									</Box>
									<Box xcss={editedDefinitionCardBodyStyles}>
										<Box xcss={editedDefinitionCardHeaderWrapperStyles}>
											<Inline xcss={editedDefinitionCardHeaderStyles}>
												{formatMessage(messages.readingAidsEditHistoryCardEdited, {
													editorName: (
														<Inline xcss={editedDefinitionCardEditorStyles}>
															{item.editor?.name || ''}
														</Inline>
													),
												})}
												{' ∙ '}
												<TimeAgo date={Number(item.createdAt) * 1000} />
											</Inline>
											{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop */}
											<hr style={editedDefinitionCardHeaderSpacerStyles} />
										</Box>
										<span data-testid={`rendered-markdown-definition-${index}`}>
											<Suspense fallback={<Fragment />}>
												<MarkdownRenderer>{item.definition}</MarkdownRenderer>
											</Suspense>
										</span>
										{item.referenceUrl && item.confluenceEntity?.title && (
											<Box xcss={editedDefinitionCardReferenceStyles}>
												<Link appearance="subtle" target="_blank" href={item.referenceUrl}>
													{item.confluenceEntity.type === 'BLOG_POST' ? (
														// TODO : https://product-fabric.atlassian.net/browse/DSP-20772
														// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
														<Blog16Icon label="" />
													) : (
														// TODO : https://product-fabric.atlassian.net/browse/DSP-20772
														// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
														<Page16Icon label="" />
													)}
													<Inline xcss={editedDefinitionCardReferenceTitleStyles}>
														{item.confluenceEntity.title || ''}
													</Inline>
												</Link>
											</Box>
										)}
									</Box>
								</Box>
							</Box>
						))
					)}
				</Stack>
			</ModalBody>
			<ModalFooter />
		</Modal>
	);
};
