// eslint-diable @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
// eslint-diable @atlaskit/design-system/no-unsafe-style-overrides
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import {
	B300,
	N0,
	N20,
	N200,
	N40,
	N400,
	N40A,
	N500,
	N60,
	N800,
	N900,
	P200,
	P300,
	P50,
} from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { TooltipPrimitive } from '@atlaskit/tooltip';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const AIAnswerFooterWrapper = styled.div<{ noAnswer?: boolean }>(({ noAnswer }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	justifyContent: noAnswer ? 'flex-start' : 'space-between',
	padding: `${token('space.050', '4px')} ${token('space.300', '24px')}`,
	borderTop: `1px solid ${token('color.border', N40A)}`,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerFeedbackWrapper = styled.div<{
	isReadingAids?: boolean;
	isCompact?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ isReadingAids, isCompact }) => ({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginTop: !isReadingAids ? token('space.100', '8px') : 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginBottom: !isReadingAids ? token('space.100', '8px') : token('space.050', '4px'),
	font: token('font.body'),
	color: token('color.text.accent.gray', N200),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: isCompact ? `0px ${token('space.300', '24px')}` : 'unset',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingLeft: isReadingAids ? token('space.250', '20px') : undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingRight: isReadingAids ? token('space.250', '20px') : undefined,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsEditedByFooterWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: `${token('space.150', '12px')} ${token('space.250', '20px')}`,
	borderTop: `1px solid ${token('color.border', N40A)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const ReadingAidsEditedByFeedbackWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerReportDescriptionWrapper = styled.div({
	paddingBottom: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FeedbackButtonIconWrapper = styled.span<{
	active: boolean;
	isDisabled: boolean;
	isReadingAids?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ active, isDisabled }) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		color: active ? B300 : `${token('color.text.subtlest', N200)} !important`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	cursor: isDisabled ? 'not-allowed' : 'default',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Rotate180IconWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		transform: 'rotate(180deg)',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerFooterTitle = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050', '4px'),
	font: token('font.heading.xxsmall'),
	color: token('color.text.subtlest', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-unsafe-style-overrides -- Ignored via go/DSP-18766
export const AIAnswerTooltipWrapper = styled(TooltipPrimitive)({
	background: token('elevation.surface.raised', N0),
	padding: token('space.100', '8px'),
	borderRadius: '3px',
	border: `1px solid ${token('color.border.information', N40A)}`,
	boxShadow: `0 8px 12px 0 ${token(
		'elevation.shadow.overlay',
		N40,
	)}, 0 0 1px 0 ${token('elevation.shadow.overlay', N60)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerInformationIconColor = token('color.text.subtlest', N200);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerInformationIconWrapper = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerInformationTooltipLink = styled.a({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	color: `${token('color.text.accent.blue', B300)} !important`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerFooterOptionWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerFollowUpWrapper = styled.div({
	display: 'flex',
	flexFlow: 'row wrap',
	alignItems: 'baseline',
	padding: `${token('space.0', '0px')} ${token('space.300', '24px')} ${token('space.050', '4px')} ${token('space.300', '24px')}`,
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerFollowupBody = styled.div({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerFollowUpPill = styled.div({
	display: 'flex',
	padding: `${token('space.025', '2px')} ${token('space.100', '8px')}`,
	color: token('color.text.subtle', N500),
	font: token('font.body'),
	borderRadius: '35px',
	backgroundColor: token('color.background.input', N0),
	border: `1px solid ${token('color.border.input', N40)}`,
	'&:hover': {
		cursor: 'pointer',
		backgroundColor: token('color.background.input.hovered', N20),
		border: `1px solid ${token('color.border.input', N40)}`,
	},
	justifyContent: 'flex-start',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerFollowUpPillText = styled.div({
	marginLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsAnswerFooterWrapper = styled.div<{
	isEdited?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ isEdited }) => ({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	flexDirection: isEdited ? 'row' : 'column',
	alignItems: 'center',
	justifyContent: 'space-between',
	borderTop: `1px solid ${token('color.border', N40A)}`,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: isEdited ? `${token('space.050', '4px')} ${token('space.250', '20px')}` : '0',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsWrapper = styled.div({
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: `${token('space.050', '4px')} ${token('space.250', '20px')}`,
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerFooterInformationContentVary = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: token('space.050', '4px'),
	color: token('color.text.subtlest', N200),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	font: token('font.body.small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerFooterInformationContentVaryLinkText = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	font: token('font.body.small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerFooterPoweredBy = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	font: token('font.body.small'),
	color: token('color.text.subtlest', N200),
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerFooterPoweredByAIWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: token('space.025', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsFooterTitle = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050', '4px'),
	font: token('font.heading.xxsmall'),
	color: token('color.text.subtle', N400),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsFeedbackLabel = styled.div({
	font: token('font.body.small'),
	color: token('color.text.subtle', '#44546F'),
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-unsafe-style-overrides -- Ignored via go/DSP-18766
export const ReadingAidsTooltipWrapper = styled(TooltipPrimitive)({
	background: token('color.background.neutral.bold', N800),
	padding: `${token('space.025', '2px')} ${token('space.075', '6px')}`,
	borderRadius: '3px',
	color: token('color.text.inverse', N0),
	fontWeight: token('font.weight.regular'),
	fontSize: '12px',
	lineHeight: '16px',
	width: '213px',
	height: '48px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsInformationIconColor = token('color.icon', '#44546F');

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsBannerWrapper = styled.div({
	display: 'flex',
	background: token('color.background.accent.purple.subtlest', P50),
	flexDirection: 'column',
	font: token('font.body'),
	padding: `${token('space.100', '8px')} ${token('space.500', '36px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsBannerHeader = styled.div({
	display: 'flex',
	color: token('color.text.accent.purple', P300),
	fontWeight: token('font.weight.semibold'),
	alignItems: 'center',
	marginLeft: token('space.negative.300', '-24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsBannerIconWrapper = styled.div({
	display: 'flex',
	color: token('color.icon.accent.purple', P200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsBannerDescription = styled.div({
	display: 'inline',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const ReadingAidsEditWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const ReadingAidsEditedByWrapper = styled.span({
	display: 'flex',
	background: token('color.background.neutral', N900),
	padding: `${token('space.0', '0px')} ${token('space.050', '4px')}`,
	borderRadius: '24px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const ReadingAidsEditHistoryWrapper = styled.div({
	display: 'flex',
	font: token('font.body.small'),
	color: token('color.link', '#0C66E4'),
	paddingLeft: token('space.300', '28px'),
	cursor: 'pointer',
	'&:hover': {
		textDecoration: 'underline',
	},
});
