import React, { Suspense, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import styled from '@emotion/styled';
import { useIntl } from 'react-intl-next';

import Button, { LinkButton } from '@atlaskit/button/new';
import DepartmentIcon from '@atlaskit/icon/core/department';
import Link from '@atlaskit/link';
import { SmartCardProvider as Provider } from '@atlaskit/link-provider';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';

import { descriptionHelper } from '../ai-answer-dialog/ai-answer-body/ai-answer-result/json/recent-work-list/description-helper';
import { useAIAnswerContext } from '../ai-answer-dialog/ai-answer-context';
import {
	type NLPSearchWhoResultCollaboratorDataType,
	type NLPSearchWorkResultRecentActivityDataType,
} from '../ai-answer-dialog/types';
import { useAnalytics } from '../common/controller/analytics';
import { ErrorBoundary } from '../common/ui/error-boundary';
import { messages } from '../messages';

import { onKnowledgeCardLinkClick } from './analytics';
import { KnowledgeCardsRow } from './knowledge-cards-row';
import { PersonCard } from './person-card';
import { SeparatorLine } from './separator-line';
import {
	EmptyStateText,
	KnowledgeCardsTeamCountText,
	KnowledgeDefaultSectionHeader,
	KnowledgeDefaultText,
	KnowledgeIconWrapper,
} from './styled';
import { KnowledgeCardsIntentType, KnowledgeCardsRowType } from './types';
import { trimARI } from './util';

type KnowledgeCardsBodyProps = {
	type: KnowledgeCardsIntentType;
	currentUserId: string;
	recommendedUsers: NLPSearchWhoResultCollaboratorDataType[];
	recentActivity: NLPSearchWorkResultRecentActivityDataType[];
	profileUrl?: string;
	viewAllUrl?: string;
};

const BodyContainer = xcss({
	position: 'relative',
});

const MutedText = xcss({
	color: 'color.text.subtlest',
	fontWeight: 'font.weight.regular',
});

const EmptyState = ({ text }: { text: string }) => {
	return (
		<Box paddingInline="space.200" paddingBlock="space.075">
			<Inline alignBlock="center">
				<EmptyStateText>{text}</EmptyStateText>
			</Inline>
		</Box>
	);
};

const HoverCard = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_.smart-card/hover-card" */ '@atlaskit/smart-card/hover-card'
	).then(({ HoverCard }) => ({
		default: HoverCard,
	})),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-unsafe-style-overrides
const CardContainer = styled(TooltipPrimitive)({
	width: '214px',
	height: '236px',
});
const EXPANDED_MAX_ITEMS_SHOWN = 6;
const MAX_ITEMS_SHOWN = 3;

export const KnowledgeCardsBody = React.memo(
	({
		type,
		currentUserId,
		recommendedUsers,
		recentActivity,
		profileUrl,
		viewAllUrl,
	}: KnowledgeCardsBodyProps) => {
		const {
			commonAttributes,
			userDetails: { id: searcherId },
		} = useAIAnswerContext();
		const [fireAnalyticsEvent] = useAnalytics();
		const { formatMessage, formatDate } = useIntl();
		const [isExpanded, setExpanded] = useState(false);
		const isPerson = type === KnowledgeCardsIntentType.PERSON;
		const backupViewAllUrl = `https://hello.atlassian.net/people/${currentUserId}/work`;

		return (
			<Box xcss={BodyContainer}>
				<Stack space="space.025">
					<Inline space="space.200">
						<Stack grow="fill" space="space.075">
							<Box
								paddingInlineStart="space.200"
								paddingBlockStart="space.150"
								paddingInlineEnd="space.300"
								paddingBlockEnd="space.075"
							>
								<Inline spread="space-between" alignBlock="center">
									<KnowledgeDefaultSectionHeader>
										{formatMessage(messages.knowledge_cards_recent_work_subheader)}
									</KnowledgeDefaultSectionHeader>
									{recentActivity.length > 0 && (
										<Link
											href={viewAllUrl || backupViewAllUrl}
											appearance="subtle"
											onClick={() => {
												fireAnalyticsEvent(
													onKnowledgeCardLinkClick(commonAttributes, 'recent-work-view-all'),
												);
											}}
										>
											<KnowledgeDefaultText>
												{formatMessage(messages.knowledge_cards_view_all_link)}
											</KnowledgeDefaultText>
										</Link>
									)}
								</Inline>
							</Box>
							<ErrorBoundary fallback={<></>}>
								<Suspense fallback={<></>}>
									<Provider>
										{!recentActivity.length ? (
											<EmptyState
												text={formatMessage(messages.knowledge_cards_recent_work_empty)}
											/>
										) : (
											recentActivity
												.slice(
													0,
													!isExpanded
														? MAX_ITEMS_SHOWN
														: Math.min(recentActivity.length, EXPANDED_MAX_ITEMS_SHOWN),
												)
												.map((activity) => (
													<HoverCard
														url={activity.url}
														key={`knowledge-card-activity-${activity.url}`}
													>
														<KnowledgeCardsRow
															primaryText={activity.title}
															secondaryText={`${activity.containerName} • ${descriptionHelper({
																currentUserId: searcherId || '',
																event: activity.event,
																contributors: activity.contributors,
																othersCount: activity.othersCount,
																formatMessage,
																formatDate,
																timestamp: new Date(activity.timestamp),
															})}`}
															pictureUrl={activity.iconUrl}
															type={KnowledgeCardsRowType.WORK}
															onClick={() => {
																fireAnalyticsEvent(
																	onKnowledgeCardLinkClick(commonAttributes, 'recent-work-item'),
																);
																window.open(activity.url, '_self', 'noopener, noreferrer');
															}}
														/>
													</HoverCard>
												))
										)}
									</Provider>
								</Suspense>
							</ErrorBoundary>
						</Stack>
						<Stack grow="fill" space="space.075">
							<Box
								paddingInlineStart="space.200"
								paddingBlockStart={isPerson ? 'space.100' : 'space.200'}
								paddingInlineEnd="space.300"
								paddingBlockEnd={isPerson ? 'space.025' : 'space.075'}
							>
								<Inline spread="space-between" alignBlock="center">
									{isPerson ? (
										<KnowledgeDefaultSectionHeader>
											{formatMessage(messages.knowledge_cards_collaborators_subheader)}
										</KnowledgeDefaultSectionHeader>
									) : (
										<Inline space="space.100" alignBlock="center">
											<KnowledgeDefaultSectionHeader>
												{formatMessage(messages.knowledge_cards_team_members_subheader)}
											</KnowledgeDefaultSectionHeader>
											<KnowledgeCardsTeamCountText>
												{recommendedUsers.length}
											</KnowledgeCardsTeamCountText>
										</Inline>
									)}
									{isPerson && (
										<KnowledgeIconWrapper>
											<Link
												href={`https://hello.atlassian.net/people/${currentUserId || ''}#workswith`}
												onClick={() => {
													fireAnalyticsEvent(
														onKnowledgeCardLinkClick(commonAttributes, 'reporting-lines-link'),
													);
												}}
											>
												<DepartmentIcon label="department" color={token('color.text')} />
											</Link>
										</KnowledgeIconWrapper>
									)}
								</Inline>
							</Box>
							{!recommendedUsers.length ? (
								<EmptyState
									text={formatMessage(
										isPerson
											? messages.knowledge_cards_collaborators_empty
											: messages.knowledge_cards_teammembers_empty,
									)}
								/>
							) : (
								recommendedUsers
									.slice(
										0,
										!isExpanded
											? MAX_ITEMS_SHOWN
											: Math.min(recommendedUsers.length, EXPANDED_MAX_ITEMS_SHOWN),
									)
									.map((user) => {
										const userUrl = `/people/${trimARI(user.accountId || '')}`;
										return (
											<Tooltip
												key={user.accountId}
												position="bottom-end"
												component={CardContainer}
												content={
													<PersonCard
														key={user.accountId}
														name={user.name}
														profilePicUrl={user.picture || ''}
														jobTitle={user.title}
														profileUrl={user.url || userUrl}
														email={user.email}
													/>
												}
											>
												{(triggerProps) => (
													<KnowledgeCardsRow
														primaryText={user.name}
														secondaryText={
															!!user.title || !!user.department
																? [user.title, user.department].filter((str) => !!str).join(', ')
																: ''
														}
														pictureUrl={user.picture || ''}
														type={KnowledgeCardsRowType.PERSON}
														triggerProps={triggerProps}
														onClick={() => {
															fireAnalyticsEvent(
																onKnowledgeCardLinkClick(commonAttributes, 'user-list-item'),
															);
															window.open(user.url || userUrl, '_self', 'noopener, noreferrer');
														}}
													/>
												)}
											</Tooltip>
										);
									})
							)}
						</Stack>
					</Inline>
					<Box paddingInline="space.200" paddingBlockStart="space.050" paddingBlockEnd="space.075">
						{recommendedUsers.length > MAX_ITEMS_SHOWN ||
						recentActivity.length > MAX_ITEMS_SHOWN ? (
							<Inline alignBlock="center" space="space.025">
								<SeparatorLine />
								{isExpanded ? (
									<LinkButton
										href={profileUrl || ''}
										onClick={() => {
											fireAnalyticsEvent(
												onKnowledgeCardLinkClick(commonAttributes, 'footer-view-all'),
											);
										}}
									>
										<Box xcss={MutedText}>
											{isPerson
												? formatMessage(messages.ai_answer_who_questions_view_profile)
												: formatMessage(messages.knowledge_cards_view_team)}
										</Box>
									</LinkButton>
								) : (
									<Button
										onClick={() => {
											setExpanded((e) => !e);
											fireAnalyticsEvent(onKnowledgeCardLinkClick(commonAttributes, 'show-more'));
										}}
									>
										<Box xcss={MutedText}>
											{formatMessage(messages.ai_answer_expand_button_label)}
										</Box>
									</Button>
								)}
								<SeparatorLine />
							</Inline>
						) : null}
					</Box>
				</Stack>
			</Box>
		);
	},
);
