import React from 'react';

import { useIntl } from 'react-intl-next';

import { IconButton, LinkButton, type LinkIconButtonProps } from '@atlaskit/button/new';
import EmailIcon from '@atlaskit/icon/core/email';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { Show } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

import { useAIAnswerContext } from '../ai-answer-dialog/ai-answer-context';
import { useAnalytics } from '../common/controller/analytics';
import { messages } from '../messages';

import { onKnowledgeCardLinkClick } from './analytics';
import {
	KnowledgeCardsLocationText,
	KnowledgeCardsPicture,
	KnowledgeCardsTimeText,
} from './styled';
import { KnowledgeCardsIntentType } from './types';
import { onEmailCopy } from './util';

type KnowledgeCardsHeaderProps = {
	type: KnowledgeCardsIntentType;
	name: string;
	email?: string | null;
	avatarUrl?: string | null;
	profileUrl?: string | null;
	jobTitle?: string | null;
	department?: string | null;
	location?: string | null;
	time?: string | null;
};

const KnowledgeCardsHeaderWrapper = xcss({
	paddingTop: 'space.150',
	paddingRight: 'space.200',
	paddingBottom: 'space.150',
	paddingLeft: 'space.200',
});

const KnowledgeCardsPrimaryInfoWrapper = xcss({
	display: 'flex',
	width: '100%',
	height: '100%',
	flexDirection: 'column',
	justifyContent: 'center',
});

const KnowledgeCardsName = xcss({
	fontSize: '20px',
	fontWeight: 'font.weight.medium',
	lineHeight: '24px',
});

const KnowledgeCardsSubText = xcss({
	fontWeight: 'font.weight.regular',
	lineHeight: '14px',
	fontSize: '11px',
	color: 'color.text.subtlest',
});

const MutedText = xcss({
	color: 'color.text.subtle',
});

export const KnowledgeCardsHeader = ({
	type,
	name,
	email,
	avatarUrl,
	profileUrl,
	jobTitle,
	department,
	location,
	time,
}: KnowledgeCardsHeaderProps) => {
	const { commonAttributes } = useAIAnswerContext();
	const { formatMessage } = useIntl();
	const [fireAnalyticsEvent] = useAnalytics();
	const isPerson = type === KnowledgeCardsIntentType.PERSON;
	const subText = isPerson
		? [jobTitle, department].filter((text) => !!text).join(', ')
		: formatMessage(messages.knowledge_cards_team_subheader);
	const trimmedSubText = subText.length > 250 ? `${subText.slice(0, 250)}...` : subText;
	const viewText = isPerson
		? messages.ai_answer_who_questions_view_profile
		: messages.knowledge_cards_view_team;
	const timeAndLocationComponent = isPerson && (
		<Inline space="space.100">
			<KnowledgeCardsTimeText>{time}</KnowledgeCardsTimeText>
			<KnowledgeCardsLocationText>{location}</KnowledgeCardsLocationText>
		</Inline>
	);

	const tooltipOptions: LinkIconButtonProps['tooltip'] = {
		position: 'bottom',
		content: formatMessage(messages.knowledge_cards_copy_email_address),
		hideTooltipOnClick: true,
	};

	return (
		<Inline alignBlock="center" space="space.100" xcss={KnowledgeCardsHeaderWrapper}>
			<Inline spread="space-between" alignInline="start" alignBlock="center" grow="fill">
				<Inline alignInline="start" alignBlock="center">
					<KnowledgeCardsPicture src={avatarUrl || ''} alt={name} />
					<Box paddingInlineStart="space.100" xcss={KnowledgeCardsPrimaryInfoWrapper}>
						<Stack space="space.075">
							<Stack>
								<Box xcss={KnowledgeCardsName}>{name}</Box>
								<Box xcss={KnowledgeCardsSubText}>{trimmedSubText}</Box>
							</Stack>
							<Show below="sm">{timeAndLocationComponent}</Show>
						</Stack>
					</Box>
				</Inline>
				<Show above="sm">{timeAndLocationComponent}</Show>
			</Inline>
			<Inline alignBlock="center" alignInline="end" space="space.100">
				{!!email && (
					<IconButton
						icon={(props) => <EmailIcon {...props} color={token('color.icon')} />}
						label={formatMessage(messages.ai_answer_who_questions_hover_email_icon)}
						onClick={() => {
							fireAnalyticsEvent(onKnowledgeCardLinkClick(commonAttributes, 'header-copy-email'));
							onEmailCopy(email);
						}}
						isTooltipDisabled={false}
						tooltip={tooltipOptions}
					/>
				)}
				<LinkButton
					href={profileUrl || ''}
					onClick={() => {
						fireAnalyticsEvent(onKnowledgeCardLinkClick(commonAttributes, 'header-view-profile'));
					}}
				>
					<Box xcss={MutedText}>{formatMessage(viewText)}</Box>
				</LinkButton>
			</Inline>
		</Inline>
	);
};
