import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { useAIAnswerContext } from '../ai-answer-dialog/ai-answer-context';
import { QueryIntent } from '../constants';

import { PersonKnowledgeCard } from './person-knowledge-card';
import { TeamKnowledgeCard } from './team-knowledge-card';

export const KnowledgeCard = () => {
	const { isAnswered, hasError, smartAnswersRoute } = useAIAnswerContext();

	const showKnowledgeCardsv2 = fg('ai_topics_knowledge_cards_v2');

	if (showKnowledgeCardsv2 && isAnswered && !hasError) {
		switch (smartAnswersRoute) {
			case QueryIntent.TEAM:
				return <TeamKnowledgeCard />;
			case QueryIntent.PERSON:
				return <PersonKnowledgeCard />;
			default:
				return null;
		}
	}

	return null;
};
