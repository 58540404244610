import React, { type ReactNode, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import Avatar from '@atlaskit/avatar';
import { IconButton, type IconButtonProps, LinkButton } from '@atlaskit/button/new';
import EmailIcon from '@atlaskit/icon/core/email';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { useAIAnswerContext } from '../ai-answer-dialog/ai-answer-context';
import { useAnalytics } from '../common/controller/analytics';
import { messages } from '../messages';

import { onKnowledgeCardLinkClick } from './analytics';
import { onEmailCopy } from './util';

interface PersonCardProps {
	name: string;
	profilePicUrl: string;

	jobTitle?: string | null;

	profileUrl?: string | null;
	email?: string | null;
	isHoverState?: boolean;
	onCardClick?: () => void;
}

const wrapperXcss = xcss({
	':hover': {
		boxShadow: 'elevation.shadow.overlay',
		borderRadius: 'border.radius.200',
	},
});

const cardXcss = xcss({
	backgroundColor: 'elevation.surface.raised',
	borderRadius: 'border.radius.200',
	boxShadow: 'elevation.shadow.raised',
	padding: 'space.200',
	width: '214px',
});

const clickableCardXcss = xcss({
	backgroundColor: 'elevation.surface.raised',
	borderRadius: 'border.radius.200',
	boxShadow: 'elevation.shadow.raised',
	padding: 'space.200',
	width: '214px',
	cursor: 'pointer',
});

const userNameXcss = xcss({
	color: 'color.text',
	font: token('font.heading.small'),
	textAlign: 'center',
	wordBreak: 'break-all',
	display: '-webkit-box',
	overflow: 'hidden',
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 1,
});

const tooltip = xcss({
	color: 'color.text.inverse',
	fontSize: '12px',
	fontWeight: 'font.weight.regular',
	lineHeight: '16px',
});

const tooltipContainer = xcss({
	backgroundColor: 'color.background.neutral.bold',
	borderRadius: '3px',
	alignItems: 'center',
	zIndex: 'tooltip',
	position: 'absolute',
	left: 'space.025',
	bottom: 'space.negative.250',
	maxWidth: '240px',
	paddingBlock: 'space.025',
	paddingInline: 'space.075',
});

const userSubheadingXcss = xcss({
	color: 'color.text.subtle',
	font: token('font.body'),
	textAlign: 'center',
	wordBreak: 'break-all',
	display: '-webkit-box',
	overflow: 'hidden',
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const tooltipOptions: IconButtonProps['tooltip'] = {
	position: 'bottom',
};

export const PersonCard = ({
	name,
	profilePicUrl,
	jobTitle,
	profileUrl,
	email,
	isHoverState = true,
	onCardClick,
}: PersonCardProps) => {
	const { commonAttributes } = useAIAnswerContext();
	const [fireAnalyticsEvent] = useAnalytics();
	const { formatMessage } = useIntl();
	const [tipState, setTipState] = useState(false);

	const Wrapper =
		!isHoverState && !!onCardClick
			? (props: { children: ReactNode }) => (
					<Box
						xcss={wrapperXcss}
						onClick={() => {
							fireAnalyticsEvent(onKnowledgeCardLinkClick(commonAttributes, 'person-card-click'));
							onCardClick();
						}}
					>
						{props.children}
					</Box>
				)
			: React.Fragment;

	return (
		<Wrapper>
			<Flex
				direction="column"
				alignItems="center"
				gap="space.200"
				xcss={!isHoverState && !!onCardClick ? clickableCardXcss : cardXcss}
			>
				<Box>
					<Avatar src={profilePicUrl} size="xlarge" />
				</Box>
				<Flex direction="column" alignItems="center" gap="space.100">
					<Box xcss={userNameXcss}>{name}</Box>
					{jobTitle && <Box xcss={userSubheadingXcss}>{jobTitle}</Box>}
				</Flex>

				<Flex gap="space.050">
					{email && (
						<IconButton
							icon={(props) => <EmailIcon {...props} color={token('color.icon')} />}
							onClick={() => {
								fireAnalyticsEvent(
									onKnowledgeCardLinkClick(commonAttributes, 'person-card-copy-email'),
								);
								onEmailCopy(email);
							}}
							label={formatMessage(messages.knowledge_cards_copy_email_address)}
							onMouseEnter={() => setTipState(true)}
							onMouseLeave={() => setTipState(false)}
							isTooltipDisabled={isHoverState}
							tooltip={tooltipOptions}
						/>
					)}

					{profileUrl && (
						<LinkButton
							href={profileUrl}
							target="_blank"
							onClick={() => {
								fireAnalyticsEvent(
									onKnowledgeCardLinkClick(commonAttributes, 'person-card-view-profile'),
								);
							}}
						>
							<FormattedMessage {...messages.ai_answer_who_questions_view_profile} />
						</LinkButton>
					)}
					{isHoverState && tipState && (
						<Box xcss={tooltipContainer}>
							<Box xcss={tooltip}>{formatMessage(messages.knowledge_cards_copy_email_address)}</Box>
						</Box>
					)}
				</Flex>
			</Flex>
		</Wrapper>
	);
};
