/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { useEffect } from 'react';

import { css, jsx } from '@compiled/react';
import Lottie from 'lottie-react';

import loadingAnimationData from './line-animation.json';

const loadingAnimationOptions = {
	loop: true,
	autoplay: true,
	animationData: loadingAnimationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

const loadingAnimationStyles = css({
	position: 'absolute',
	top: '-23px',
	left: '-19px',
	width: '320px',
	height: '2px',
});

type LineAnimationLoaderProps = {
	onAnimationShown: () => void;
};

export const LineAnimationLoader = ({ onAnimationShown }: LineAnimationLoaderProps) => {
	useEffect(() => {
		onAnimationShown?.();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Lottie {...loadingAnimationOptions} css={loadingAnimationStyles} />;
};
