import React from 'react';

import { type NLPSearchResultSource } from '../../../ai-answer-dialog/types';
import { extractUrlHostname } from '../../../utils';
import { ErrorBoundary } from '../error-boundary';

const Card = React.lazy(() =>
	import(/* webpackChunkName: "@atlaskit-internal_.smart-card" */ '@atlaskit/smart-card').then(
		({ Card }) => ({
			default: Card,
		}),
	),
);

const resolveUrlPlaceholder = (url: string) => {
	const textFragmentRegex = new RegExp('#:~:text=.*$', 'g');
	const urlWithoutTextFragment = url?.replace(textFragmentRegex, '');
	const maxLength = 30;
	const truncatedUrl =
		urlWithoutTextFragment.length > maxLength
			? urlWithoutTextFragment.slice(0, maxLength) + '...'
			: urlWithoutTextFragment;
	return truncatedUrl;
};

const fallbackComponent =
	({
		index,
		source,
		searchSessionId,
		onClickHandler,
	}: Omit<SourceCardProps, 'enableSmartLinkSources'>) =>
	() => {
		return (
			<a
				href={`${source.url}?search_id=${searchSessionId}`}
				onClick={(e) => onClickHandler(index, extractUrlHostname(source.url))}
				rel="noopener noreferrer"
				target="_blank"
			>
				{source.title ?? source.url}
			</a>
		);
	};

type SourceCardProps = {
	index: number;
	source: NLPSearchResultSource;
	searchSessionId: string;
	onClickHandler: (index: number, hostname?: string) => void;
	enableSmartLinkSources: boolean;
};

export const SourceCard = ({
	index,
	source,
	searchSessionId,
	onClickHandler,
	enableSmartLinkSources,
}: SourceCardProps) => {
	const SmartLinkSource = (
		<Card
			appearance="inline"
			url={source.url}
			removeTextHighlightingFromTitle
			showHoverPreview
			fallbackComponent={fallbackComponent({
				index,
				source,
				searchSessionId,
				onClickHandler,
			})}
			onClick={(e) => {
				onClickHandler(index, extractUrlHostname(source.url));
				e.preventDefault();
				window.open(`${source.url}?search_id=${searchSessionId}`, '_blank', 'noopener, noreferrer');
			}}
			onError={() => {
				// Nothing for now
			}}
			resolvingPlaceholder={resolveUrlPlaceholder(source.url)}
			placeholder={resolveUrlPlaceholder(source.url)}
		/>
	);

	return (
		<ErrorBoundary fallback={<></>}>
			{enableSmartLinkSources
				? SmartLinkSource
				: fallbackComponent({ index, source, searchSessionId, onClickHandler })()}
		</ErrorBoundary>
	);
};
