import React, { useCallback, useState } from 'react';

import debouncePromise from 'debounce-promise';
import { useIntl } from 'react-intl-next';

import Button, { IconButton } from '@atlaskit/button/new';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import ErrorIcon from '@atlaskit/icon/utility/migration/error';
import Modal from '@atlaskit/modal-dialog';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import Select, { AsyncSelect, type InputActionMeta, type SelectProps } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { setSessionItem } from '@atlassian/search-dialog';

import { useAIAnswerContext } from '../../../../ai-answer-dialog/ai-answer-context';
import { SEARCH_AI_ANSWER_CACHE_KEY } from '../../../../ai-answer-dialog/ai-answer-dialog';
import { onCuratedDefinitionUpdateButtonClicked } from '../../analytics';
import { updateDefinitionErrorPayload } from '../../error-analytics';
import {
	type CuratedDefinitionScopeConfigType,
	type CuratedDefinitionSourceType,
	EditedDefinitionScope,
	type RestrictionOptionType,
} from '../types';

import { MarkdownEditor } from './markdown-editor';
import { messages } from './messages';
import { RadialProgressBar } from './radial-progress-bar';
import { restrictionOptionFormatter } from './restriction-option-formatter';
import { SourceOption } from './source-option-formatter';
import { useCreateDefinitionSupplier } from './useCreateDefinitionSupplier';
import { useSearchSourceSupplier } from './useSearchSourceSupplier';

type EditDefinitionModalProps = {
	curatedDefinitionScopeConfig: CuratedDefinitionScopeConfigType;
	closeEditModal: () => void;
	openFeedbackOptionsModal?: () => void;
	isCreateDefinition?: boolean;
};

const MAX_DEFINITION_LENGTH = 500;

const EditDefinitionModalWrapperStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	padding: 'space.300',
	gap: 'space.100',
});

const EditDefinitionModalHeaderStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
});

const EditDefinitionModalHeaderTextStyles = xcss({
	font: token('font.heading.medium'),
});

const EditDefinitionModalBodyStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.050',
	font: token('font.body'),
	color: 'color.text',
});

const EditDefinitionModalSectionLabelStyles = xcss({
	font: token('font.heading.xxsmall'),
	color: 'color.text.subtle',
	marginTop: 'space.150',
});

const EditDefinitionModalTextBoxRadialTextStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.075',
	font: token('font.body.small'),
	color: 'color.text',
});

const EditDefinitionModalNoDefinitionTextStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	color: 'color.text.danger',
	font: token('font.body.small'),
	gap: 'space.050',
});

export const EditDefinitionModal = ({
	curatedDefinitionScopeConfig,
	closeEditModal,
	openFeedbackOptionsModal,
	isCreateDefinition,
}: EditDefinitionModalProps) => {
	const { formatMessage } = useIntl();
	const { fireAnalyticsEvent, commonAttributes, setData, answerEditor, additionalQueryContext } =
		useAIAnswerContext();
	const { answerString, query: keyPhrase } = commonAttributes;

	const [editedDefinition, setEditedDefinition] = useState(answerString);
	const updateDefinitionDisabled = !editedDefinition.length;
	const [charCount, setCharCount] = useState<number>(0);

	const [sourceSearchInput, setSourceSearchInput] = useState('');
	const [source, setSource] = useState<CuratedDefinitionSourceType | null>(null);

	const { definitionRestrictionOptions } = curatedDefinitionScopeConfig;
	const defaultRestrictionOption = definitionRestrictionOptions[0];
	const [audienceRestriction, setAudienceRestriction] = useState<EditedDefinitionScope>(
		defaultRestrictionOption.value,
	);

	const [updateDefinitionLoading, setUpdateDefinitionLoading] = useState(false);

	const setDefinition = useCallback(
		(value: string) => {
			setEditedDefinition(value);
		},
		[setEditedDefinition],
	);

	const { searchSources } = useSearchSourceSupplier();

	const onSourceSearchInputChange = (input: string, actionMeta: InputActionMeta) => {
		if (actionMeta.action === 'input-change') {
			setSourceSearchInput(input);
		}
	};

	const onSelectSource: SelectProps<CuratedDefinitionSourceType>['onChange'] = (
		newValue,
		actionMeta,
	) => {
		if (actionMeta.action === 'select-option') {
			setSource(newValue);
		}
	};

	const onSelectRestrictionOption: SelectProps<RestrictionOptionType>['onChange'] = (
		newValue,
		actionMeta,
	) => {
		if (actionMeta.action === 'select-option') {
			setAudienceRestriction(newValue?.value || EditedDefinitionScope.ORGANIZATION);
		}
	};

	const { createDefinition } = useCreateDefinitionSupplier({
		curatedDefinitionScopeConfig,
	});
	const onUpdateDefinition = async () => {
		setUpdateDefinitionLoading(true);
		try {
			const updatedDefinition = await createDefinition({
				keyPhrase,
				editedDefinition,
				scope: audienceRestriction,
				referenceContentId: source ? source.id : null,
				referenceUrl: source ? source.url : null,
			});
			setUpdateDefinitionLoading(false);
			setSource(null);
			fireAnalyticsEvent(
				onCuratedDefinitionUpdateButtonClicked({
					commonAttributes,
					newDefinition: editedDefinition,
					restrictedScope: audienceRestriction,
					sourceSelected: !!source,
					aiDefinitionEdited: !!!answerEditor,
				}),
			);
			setData(updatedDefinition);
			setSessionItem(
				SEARCH_AI_ANSWER_CACHE_KEY,
				JSON.stringify({
					query: keyPhrase,
					additionalContext: additionalQueryContext || '',
					data: updatedDefinition,
				}),
			);
			closeEditModal();
		} catch (error: unknown) {
			fireAnalyticsEvent(updateDefinitionErrorPayload(error, commonAttributes));
			setUpdateDefinitionLoading(false);
		}
	};

	return (
		<Modal shouldReturnFocus={false}>
			<Box xcss={EditDefinitionModalWrapperStyles}>
				<Box xcss={EditDefinitionModalHeaderStyles}>
					<Box xcss={EditDefinitionModalHeaderTextStyles}>
						{formatMessage(
							isCreateDefinition
								? messages.edit_definition_modal_header_create
								: messages.edit_definition_modal_header_edit,
						)}
					</Box>
					<IconButton
						appearance="subtle"
						onClick={() => closeEditModal()}
						label="close-edit-definition-modal"
						icon={EditorCloseIcon}
					/>
				</Box>
				<Box xcss={EditDefinitionModalBodyStyles}>
					<Flex justifyContent="space-between" alignItems="center">
						<Box xcss={EditDefinitionModalSectionLabelStyles}>
							{formatMessage(messages.edit_definition_text_box_label)}
						</Box>
						<Box xcss={EditDefinitionModalTextBoxRadialTextStyles}>
							{charCount}/{MAX_DEFINITION_LENGTH}
							<RadialProgressBar current={charCount} max={MAX_DEFINITION_LENGTH} />
						</Box>
					</Flex>
					<MarkdownEditor
						value={editedDefinition}
						setValue={setDefinition}
						setCharCount={setCharCount}
						maxCharacters={MAX_DEFINITION_LENGTH}
					/>
					{updateDefinitionDisabled && (
						<Box xcss={EditDefinitionModalNoDefinitionTextStyles}>
							<ErrorIcon
								label=""
								LEGACY_size="small"
								color={token('color.text.danger', '#AE2E24')}
							/>
							{formatMessage(messages.edit_definition_no_definition)}
						</Box>
					)}
					<Flex justifyContent="start" alignItems="center">
						<Box xcss={EditDefinitionModalSectionLabelStyles}>
							{formatMessage(
								source
									? messages.edit_definition_source_label
									: messages.edit_definition_add_source_label,
							)}
						</Box>
					</Flex>
					{source ? (
						<SourceOption
							source={source}
							onClickSource={() => {
								window.open(source.url, '_blank')?.focus();
							}}
							canClear
							onRemoveSource={(e) => {
								e.stopPropagation();
								setSource(null);
							}}
						/>
					) : (
						<AsyncSelect
							components={{
								DropdownIndicator: () => null,
							}}
							loadOptions={debouncePromise((input: string) => searchSources(input), 500)}
							formatOptionLabel={(sourceOption: CuratedDefinitionSourceType) => (
								<SourceOption source={sourceOption} canClear={false} />
							)}
							onChange={onSelectSource}
							inputValue={sourceSearchInput}
							onInputChange={onSourceSearchInputChange}
							placeholder={formatMessage(messages.edit_definition_source_search_box_placeholder)}
						/>
					)}
					<Flex justifyContent="start" alignItems="center">
						<Box xcss={EditDefinitionModalSectionLabelStyles}>
							{formatMessage(messages.edit_definition_restricted_to_label)}
						</Box>
					</Flex>
					<Select
						options={definitionRestrictionOptions}
						defaultValue={defaultRestrictionOption}
						onChange={onSelectRestrictionOption}
						formatOptionLabel={restrictionOptionFormatter}
						isSearchable={false}
					/>
				</Box>
				<Flex alignItems="center" justifyContent="end" gap="space.100">
					{openFeedbackOptionsModal && (
						<Button
							appearance="subtle"
							onClick={() => {
								closeEditModal();
								openFeedbackOptionsModal();
							}}
						>
							{formatMessage(messages.edit_definition_back_button)}
						</Button>
					)}
					<Button
						appearance={updateDefinitionDisabled ? 'default' : 'primary'}
						isLoading={updateDefinitionLoading}
						onClick={onUpdateDefinition}
						isDisabled={updateDefinitionDisabled}
					>
						{formatMessage(messages.edit_definition_update_button)}
					</Button>
				</Flex>
			</Box>
		</Modal>
	);
};
