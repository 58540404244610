import { type userType } from '../common-types';

export interface KnowledgeCardTeamSearchRelevanceType {
	knowledgeDiscovery: {
		searchTeam: {
			id?: string | null | undefined;
			message?: string | null | undefined;
		};
	};
}

export interface KnowledgeCardTeamSearchRelevanceVariables {
	teamQuery: string;
	orgId: string;
	siteId: string;
}

export const KNOWLEDGE_CARD_TEAM_SEARCH_RELEVANCE_OPERATION_NAME = 'KnowledgeCardTeamSearchQuery';
export const KnowledgeCardTeamSearchRelevanceQuery = `
query ${KNOWLEDGE_CARD_TEAM_SEARCH_RELEVANCE_OPERATION_NAME}($teamQuery: String!, $orgId: String!, $siteId: String!) {
  knowledgeDiscovery {
   	searchTeam (
        teamName: $teamQuery
        orgId: $orgId
        siteId: $siteId
    ) @optIn(to: "KnowledgeDiscovery Search Team"){
      ... on KnowledgeDiscoveryTeam {
        id
      }
      ... on QueryError {
        message
      }
    }
  }
}
`;

export interface KnowledgeCardTeamSearchMemberNodeType {
	member?: userType | null;
	state?: string | null;
}

export interface KnowledgeCardTeamDataType {
	team: {
		teamV2?: {
			id: string;
			displayName?: string | null;
			smallAvatarImageUrl?: string | null;
			members: {
				nodes: KnowledgeCardTeamSearchMemberNodeType[];
			};
		} | null;
	};
}

export interface KnowledgeCardTeamDataVariables {
	teamId: string;
	siteId: string;
}

export const KNOWLEDGE_CARD_TEAM_DATA_OPERATION_NAME = 'KnowledgeCardTeamDataQuery';
export const KnowledgeCardTeamDataQuery = `
query ${KNOWLEDGE_CARD_TEAM_DATA_OPERATION_NAME}($teamId: ID!, $siteId: String!) {
    team {
      teamV2 (
        id: $teamId
        siteId: $siteId
      ) @optIn(to: "Team-v2") {
        id
        displayName
        smallAvatarImageUrl
        members {
          nodes {
            member {
              id
              accountId
              name
              picture
              ... on AtlassianAccountUser {
                email
                extendedProfile {
                  jobTitle
                  department
                }
              }
            }
            state
          }
        }
      }
    }
  }
`;

/**
 * BELOW SOON TO BE REMOVED
 */
export interface KnowledgeCardTeamSearchNodeType {
	team?: {
		id: string;
		displayName?: string | null;
		smallAvatarImageUrl?: string | null;
		members: {
			nodes: KnowledgeCardTeamSearchMemberNodeType[];
		};
	} | null;
	memberCount?: number | null;
}

export interface KnowledgeCardTeamSearchType {
	team: {
		teamSearchV2: {
			nodes: KnowledgeCardTeamSearchNodeType[];
		};
	};
}

export interface KnowledgeCardTeamSearchVariables {
	teamQuery: string;
	orgId: string;
	tenantId: string;
}

export const KNOWLEDGE_CARD_TEAM_SEARCH_OPERATION_NAME = 'KnowledgeCardTeamSearchQuery';
export const KnowledgeCardTeamSearchQuery = `
query ${KNOWLEDGE_CARD_TEAM_SEARCH_OPERATION_NAME}($teamQuery: String!, $orgId: ID!, $tenantId: String!) {
  team {
    teamSearchV2 (
      filter: {
        query: $teamQuery
      }
      first: 1
      organizationId: $orgId
      siteId: $tenantId
    ) @optIn(to: "Team-search-v2") {
      nodes {
        memberCount
        team {
          id
          displayName
          smallAvatarImageUrl
          members{
            nodes {
              member {
                id
                accountId
                name
                picture
                ... on AtlassianAccountUser {
                  email
                  extendedProfile {
                    jobTitle
                    department
                  }
                }
              }
              state
            }
          }
        }
      }
    }
  }
}
`;
