// vendored from packages/editor/editor-plugin-ai/src/experience-application/screens-with-logic/LoadingScreenWithLogic.tsx
// similar logic also exists in packages/editor/renderer/src/ui/Renderer/index.tsx
import React, { useEffect, useRef, useState } from 'react';

import { AIAnswerLoadingStreamMarkdownContainer, AIAnswerResultBlurSection } from './styled';

const MarkdownRenderer = React.lazy(() =>
	import(/* webpackChunkName: "react-markdown" */ 'react-markdown').then(
		({ default: ReactMarkdown }) => {
			return {
				default: ReactMarkdown,
			};
		},
	),
);

const isScrolledToEnd = (element: HTMLElement | null) => {
	return (
		!!element && Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 10
	);
};

export const AIAnswerLoadingStream = ({ markdown }: { markdown: string }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [showResultBlur, setShowResultBlur] = useState<boolean>(false);

	useEffect(() => {
		const renderer = containerRef.current!;

		const mutateTelepointer = (mutations: MutationRecord[]) => {
			mutations.forEach(() => {
				if (containerRef && !showResultBlur && !isScrolledToEnd(containerRef.current)) {
					setShowResultBlur(true);
				}
			});
		};
		const observer = new MutationObserver(mutateTelepointer);

		observer.observe(renderer, {
			characterData: true,
			attributes: false,
			childList: true,
			subtree: true,
		});

		return () => observer.disconnect();
	});

	return (
		<>
			<AIAnswerLoadingStreamMarkdownContainer ref={containerRef}>
				<MarkdownRenderer>{markdown}</MarkdownRenderer>
			</AIAnswerLoadingStreamMarkdownContainer>
			{showResultBlur && <AIAnswerResultBlurSection />}
		</>
	);
};
