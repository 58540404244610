import React, { useCallback, useMemo } from 'react';

/* eslint-disable @atlaskit/editor/warn-no-restricted-imports */
import type { EditorActions } from '@atlaskit/editor-core';
import { ComposableEditor } from '@atlaskit/editor-core/composable-editor';
import { EditorContext } from '@atlaskit/editor-core/editor-context';
import { createDefaultPreset } from '@atlaskit/editor-core/preset-default';
import { usePreset } from '@atlaskit/editor-core/use-preset';
import { MarkdownTransformer } from '@atlaskit/editor-markdown-transformer';
import { indentationPlugin } from '@atlaskit/editor-plugins/indentation';
import { listPlugin } from '@atlaskit/editor-plugins/list';
import { maxContentSizePlugin } from '@atlaskit/editor-plugins/max-content-size';
import { toolbarListsIndentationPlugin } from '@atlaskit/editor-plugins/toolbar-lists-indentation';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { ADFEncoder } from '@atlaskit/renderer/utils';
import { convertProsemirrorToMarkdown } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown';

type MarkdownEditorProps = {
	value: string;
	setValue: (value: string) => void;
	setCharCount?: (count: number) => void;
	maxCharacters?: number;
};

export const MarkdownEditor = ({
	value,
	setValue,
	setCharCount,
	maxCharacters,
}: MarkdownEditorProps) => {
	const { preset } = usePreset(
		() =>
			createDefaultPreset({
				allowUndoRedoButtons: true,
				textFormatting: {
					responsiveToolbarMenu: true,
				},
			})
				.add(indentationPlugin)
				.add(listPlugin)
				.add([
					toolbarListsIndentationPlugin,
					{ showIndentationButtons: true, allowHeadingAndParagraphIndentation: false },
				])
				.maybeAdd([maxContentSizePlugin, maxCharacters], maxContentSizePlugin !== undefined),
		[],
	);

	const adfEncoder = useMemo(() => new ADFEncoder((schema) => new MarkdownTransformer(schema)), []);

	const initializeEditor = useCallback(
		(actions: EditorActions) => {
			const encodedValue = adfEncoder.encode(value);
			actions.replaceDocument(encodedValue);
		},
		[value, adfEncoder],
	);

	const onEditorChange = useCallback(
		(editorView: EditorView) => {
			const { markdown } = convertProsemirrorToMarkdown({
				editorView: editorView,
				fragment: editorView.state.doc.content,
			});
			setValue(markdown);
			setCharCount?.(markdown.length ? editorView.state.doc.nodeSize : 0);
		},
		[setValue, setCharCount],
	);

	return (
		<EditorContext>
			<ComposableEditor
				appearance="comment"
				preset={preset}
				shouldFocus={true}
				quickInsert={false}
				minHeight={170}
				maxHeight={90}
				onEditorReady={initializeEditor}
				onChange={onEditorChange}
			/>
		</EditorContext>
	);
};
