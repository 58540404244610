import { NLPSearchJSONResultFormat, NLPSearchResultFormat } from '../types';

import {
	getTypedJSONResultData,
	getTypedJSONResultDataAssistanceService,
} from './getTypedJSONResultData';

export const getNumAddtlWhoMatches = ({
	answerString,
	answerFormat,
}: {
	answerString: string;
	answerFormat: NLPSearchResultFormat | null;
}): number => {
	if (answerString && answerFormat === NLPSearchResultFormat.JSON) {
		const { parsedResult } = getTypedJSONResultDataAssistanceService(answerString);
		return Array.isArray(parsedResult) ? parsedResult.length - 1 : 0;
	}
	return 0;
};

export const getKnowledgeCardResultCount = ({ answerString }: { answerString: string }): number => {
	if (!answerString) {
		return 0;
	}

	const { parsedResult, parsedType } = getTypedJSONResultData(answerString);

	switch (parsedType) {
		case NLPSearchJSONResultFormat.TEAM:
			return 'id' in parsedResult && parsedResult.id ? 1 : 0;
		case NLPSearchJSONResultFormat.WHO:
		case NLPSearchJSONResultFormat.PERSON:
		case NLPSearchJSONResultFormat.WORK:
			return Array.isArray(parsedResult) ? parsedResult.length : 0;
		default:
			return 0;
	}
};
