import React from 'react';

import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import EditIcon from '@atlaskit/icon/core/migration/edit';
import EditorFeedbackIcon from '@atlaskit/icon/core/migration/feedback--editor-feedback';
import Modal from '@atlaskit/modal-dialog';
import { Box, Flex, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { messages } from './messages';

type FeedbackOptionModalProps = {
	setShowFeedbackOptionsModal: (showModal: boolean) => void;
	setShowEditDefinitionModal: (showModal: boolean) => void;
	setShowFeedbackModal: (showModal: boolean) => void;
	editDefinitionEnabled: boolean;
};

const FeedbackOptionsModalWrapperStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	padding: 'space.300',
});

const FeedbackOptionsModalHeaderStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	marginBottom: 'space.250',
});

const FeedbackOptionsModalHeaderTextStyles = xcss({
	font: token('font.heading.medium'),
});

const FeedbackOptionsWrapperStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: 'space.200',
});

const FeedbackOptionStyles = xcss({
	borderRadius: '3px',
	width: '100%',
	height: '56px',
	backgroundColor: 'color.background.neutral.subtle',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

const FeedbackOptionIconWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '2px',
	height: '32px',
	width: '32px',
});

const FeedbackOptionTextStyles = xcss({
	font: token('font.body'),
	color: 'color.text',
});

const FeedbackOptionSubTextStyles = xcss({
	font: token('font.body.small'),
	color: 'color.text.subtlest',
});

export const FeedbackOptionsModal = ({
	setShowFeedbackOptionsModal,
	setShowEditDefinitionModal,
	setShowFeedbackModal,
	editDefinitionEnabled,
}: FeedbackOptionModalProps) => {
	const { formatMessage } = useIntl();

	return (
		<Modal shouldReturnFocus={false}>
			<Box xcss={FeedbackOptionsModalWrapperStyles}>
				<Box xcss={FeedbackOptionsModalHeaderStyles}>
					<Box xcss={FeedbackOptionsModalHeaderTextStyles}>
						{formatMessage(
							editDefinitionEnabled
								? messages.definition_footer_feedback_options_modal_header
								: messages.definition_footer_feedback_options_modal_header_feedback_only,
						)}
					</Box>
					<IconButton
						appearance="subtle"
						onClick={() => setShowFeedbackOptionsModal(false)}
						label="close-feedback-options-modal"
						icon={EditorCloseIcon}
					/>
				</Box>
				<Box xcss={FeedbackOptionsWrapperStyles}>
					{editDefinitionEnabled && (
						<Pressable
							xcss={FeedbackOptionStyles}
							onClick={() => {
								setShowFeedbackOptionsModal(false);
								setShowEditDefinitionModal(true);
							}}
						>
							<Flex gap="space.100">
								<Box
									backgroundColor="color.background.accent.blue.subtle"
									xcss={FeedbackOptionIconWrapperStyles}
								>
									<EditIcon
										LEGACY_size="medium"
										spacing="spacious"
										label=""
										color={token('color.icon.inverse', '#FFFFFF')}
									/>
								</Box>
								<Flex direction="column" gap="space.025" alignItems="start">
									<Box xcss={FeedbackOptionTextStyles}>
										{formatMessage(
											messages.definition_footer_feedback_options_edit_definition_option,
										)}
									</Box>
									<Box xcss={FeedbackOptionSubTextStyles}>
										{formatMessage(
											messages.definition_footer_feedback_options_edit_definition_option_subtext,
										)}
									</Box>
								</Flex>
							</Flex>
						</Pressable>
					)}
					<Pressable
						xcss={FeedbackOptionStyles}
						onClick={() => {
							setShowFeedbackOptionsModal(false);
							setShowFeedbackModal(true);
						}}
					>
						<Flex gap="space.100">
							<Box
								backgroundColor="color.background.accent.blue.subtle"
								xcss={FeedbackOptionIconWrapperStyles}
							>
								<EditorFeedbackIcon
									LEGACY_size="medium"
									spacing="spacious"
									label=""
									color={token('color.icon.inverse', '#FFFFFF')}
								/>
							</Box>
							<Flex direction="column" gap="space.025" alignItems="start">
								<Box xcss={FeedbackOptionTextStyles}>
									{formatMessage(messages.definition_footer_feedback_options_leave_feedback_option)}
								</Box>
								<Box xcss={FeedbackOptionSubTextStyles}>
									{formatMessage(
										messages.definition_footer_feedback_options_leave_feedback_option_subtext,
									)}
								</Box>
							</Flex>
						</Flex>
					</Pressable>
				</Box>
			</Box>
		</Modal>
	);
};
