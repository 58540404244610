import React, { Fragment, useCallback } from 'react';

import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import ThumbUpIcon from '@atlaskit/icon/core/thumbs-up';
import { ThumbUpIcon as LegacyThumbUpIcon } from '@atlaskit/legacy-custom-icons';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { messages } from '../../messages';
import { useAIAnswerContext } from '../ai-answer-context';
import { onDislikeButtonClick, onLikeButtonClick } from '../analytics';

import { FeedbackButtonIconWrapper, Rotate180IconWrapper } from './styled';

type FeedbackButtonProps = {
	active: boolean;
	onClick: (e: any) => void;
	tooltipContent: string;
	icon: JSX.Element;
	isDisabled?: boolean;
	testId?: string;
	isReadingAids?: boolean;
};

const FeedbackButton = ({
	active,
	isDisabled,
	onClick: onClickHandler,
	tooltipContent,
	testId,
	icon,
	isReadingAids,
}: FeedbackButtonProps) => {
	return (
		<Tooltip position={'top'} content={tooltipContent}>
			{({ onClick, ...tooltipProps }) => (
				<Button
					aria-label={tooltipContent}
					spacing={'none'}
					appearance={isDisabled ? 'subtle-link' : 'subtle'}
					iconBefore={
						<FeedbackButtonIconWrapper
							active={active}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={{ cursor: 'pointer' }}
							{...(testId && { 'data-testid': testId })}
							isDisabled={isDisabled || false}
							isReadingAids={isReadingAids || false}
						>
							{icon}
						</FeedbackButtonIconWrapper>
					}
					{...(!isDisabled && { onClick: onClickHandler })}
					{...tooltipProps}
				/>
			)}
		</Tooltip>
	);
};

type FeedbackButtonsProps = {
	setDisplayFlag: (value: boolean) => void;
	setFeedbackModal: (value: boolean) => void;
};

export const FeedbackButtons = ({ setDisplayFlag, setFeedbackModal }: FeedbackButtonsProps) => {
	const { formatMessage } = useIntl();
	const {
		commonAttributes,
		fireAnalyticsEvent,
		updateContextCache,
		liked,
		setLiked,
		likesDisabled,
		setLikesDisabled,
		isReadingAids,
	} = useAIAnswerContext();
	const { trackAIFeedbackSubmit } = useAIEventsInstrumentation();
	// Using a single state to represent both like/dislike states
	// true - liked, false - disliked, undefined - neither are selected
	const likeButtonCallback = useCallback(
		(e: any) => {
			e.preventDefault();
			setDisplayFlag(true);
			setLiked(true);
			setLikesDisabled(true);
			updateContextCache({ collapsed: false, liked: true });
			fireAnalyticsEvent(onLikeButtonClick(commonAttributes));
			trackAIFeedbackSubmit('up');
		},
		[
			setLiked,
			setLikesDisabled,
			fireAnalyticsEvent,
			commonAttributes,
			updateContextCache,
			setDisplayFlag,
			trackAIFeedbackSubmit,
		],
	);

	const dislikeButtonCallback = useCallback(
		(e: any) => {
			e.preventDefault();
			setFeedbackModal(true);
			setLiked(false);
			setLikesDisabled(true);
			updateContextCache({ collapsed: false, liked: false });
			fireAnalyticsEvent(onDislikeButtonClick(commonAttributes));
			trackAIFeedbackSubmit('down');
		},
		[
			setLiked,
			setLikesDisabled,
			fireAnalyticsEvent,
			commonAttributes,
			updateContextCache,
			setFeedbackModal,
			trackAIFeedbackSubmit,
		],
	);

	return (
		<Fragment>
			<FeedbackButton
				active={liked === true}
				onClick={likeButtonCallback}
				tooltipContent={formatMessage(messages.ai_answer_component_like_button_tooltip)}
				icon={
					<ThumbUpIcon
						label=""
						spacing="spacious"
						color={token('color.icon', N500)}
						LEGACY_fallbackIcon={LegacyThumbUpIcon}
					/>
				}
				isDisabled={liked && likesDisabled}
				testId="ai-answer-like-button"
				isReadingAids={isReadingAids}
			/>
			<FeedbackButton
				active={liked === false}
				onClick={dislikeButtonCallback}
				tooltipContent={formatMessage(messages.ai_answer_component_dislike_button_tooltip)}
				icon={
					<Rotate180IconWrapper>
						<ThumbUpIcon
							label=""
							spacing="spacious"
							color={token('color.icon', N500)}
							LEGACY_fallbackIcon={LegacyThumbUpIcon}
						/>
					</Rotate180IconWrapper>
				}
				isDisabled={!liked && likesDisabled}
				testId="ai-answer-dislike-button"
				isReadingAids={isReadingAids}
			/>
		</Fragment>
	);
};
