// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { B100, N10, N600, N800, N90 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RecentWorkListTitle = styled.h5({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RecentWorkListWrapper = styled.div({
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RecentWorkItemLink = styled.a({
	position: 'relative',
	margin: 0,
	overflow: 'hidden',
	display: 'block',
	paddingLeft: token('space.100', '10px'),
	paddingRight: token('space.100', '10px'),
	borderRadius: token('border.radius.100', '3px'),
	'&:hover': {
		background: token('color.background.neutral.subtle.hovered', N10),
		textDecoration: 'none',
	},
	'&:focus': {
		outline: 0,
		boxShadow: `0 0 0 ${token('space.025', '2px')} ${token('color.border.focused', B100)}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RecentWorkItemWrapper = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RecentWorkItemIcon = styled.img({
	borderRadius: token('border.radius.100', '3px'),
	display: 'flex',
	flexShrink: 0,
	width: token('space.300', '24px'),
	height: token('space.300', '24px'),
	marginTop: token('space.100', '8px'),
	marginBottom: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RecentWorkItemBody = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: token('space.200', '16px'),
	justifyContent: 'center',
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RecentWorkItemTitle = styled.div({
	paddingRight: token('space.100', '10px'),
	paddingBottom: token('space.025', '2px'),
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	p: {
		display: 'inline-block',
		font: token('font.heading.xsmall'),
		margin: 0,
		color: token('color.text', N800),
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RecentWorkItemByline = styled.p({
	font: token('font.body.small'),
	color: token('color.text.subtlest', N90),
	margin: 0,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	paddingRight: token('space.100', '10px'),
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RecentWorkItemContainer = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:after': {
		content: "'\\00B7'",
		paddingRight: token('space.050', '4px'),
		paddingLeft: token('space.050', '4px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-empty-styled-expression -- Ignored via go/DSP-18766
export const RecentWorkItemDescription = styled.span({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LinkWrapper = styled.div({
	display: 'flex',
	marginLeft: token('space.100', '10px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'button, a, span': {
		font: token('font.body.small'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		padding: `${token('space.0', '0px')} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		height: 'unset !important',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyStateTextWrapper = styled.div({
	marginTop: token('space.200', '16px'),
	font: token('font.body'),
	color: token('color.text.accent.gray', N600),
});
