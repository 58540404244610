import { type ActivityClient, type ActivityItem } from '@atlassian/recent-work-client';

import {
	NLPSearchErrorState,
	NLPSearchResultFormat,
	type NLPSearchTeamResultType,
	type NLPSearchWhoResultCollaboratorDataType,
	type SearchAIAnswerQueryType,
} from '../../../ai-answer-dialog/types';
import { fetchAggResponse } from '../../../common/fetch';
import { sanitizeAndMapRecentWorkActivity, trimARI } from '../../util';

import {
	KNOWLEDGE_CARD_TEAM_DATA_OPERATION_NAME,
	KNOWLEDGE_CARD_TEAM_SEARCH_OPERATION_NAME,
	KNOWLEDGE_CARD_TEAM_SEARCH_RELEVANCE_OPERATION_NAME,
	KnowledgeCardTeamDataQuery,
	type KnowledgeCardTeamDataType,
	type KnowledgeCardTeamDataVariables,
	type KnowledgeCardTeamSearchMemberNodeType,
	type KnowledgeCardTeamSearchNodeType,
	KnowledgeCardTeamSearchQuery,
	KnowledgeCardTeamSearchRelevanceQuery,
	type KnowledgeCardTeamSearchRelevanceType,
	type KnowledgeCardTeamSearchRelevanceVariables,
	type KnowledgeCardTeamSearchType,
	type KnowledgeCardTeamSearchVariables,
} from './gql';

type ActivitySectionType = ActivityItem[];

type TeamKnowledgeCardRelevanceVariables = {
	teamQuery: string;
	orgId: string;
	siteId: string;
	activityClient: ActivityClient;
	sourceProduct?: string;
};

type TeamKnowledgeCardVariables = {
	teamQuery: string;
	orgId: string;
	tenantId: string;
	activityClient: ActivityClient;
};

const NO_TEAM_MESSAGE = 'NO_TEAM';
export const noTeamResult: NLPSearchTeamResultType = {
	team_metadata: {
		id: '',
		name: '',
		avatarUrl: null,
		url: null,
		memberCount: 0,
		members: [],
		recentActivity: [],
	},
};

export const fetchTeamKnowledgeCardDataWithRanking = ({
	activityClient,
	teamQuery,
	orgId,
	siteId,
	sourceProduct,
}: TeamKnowledgeCardRelevanceVariables): Promise<SearchAIAnswerQueryType> => {
	let teamResult: string | undefined;
	let teamData: KnowledgeCardTeamDataType | undefined;
	let activityResult: ActivitySectionType | undefined;

	const siteARI = sourceProduct ? `ari:cloud:${sourceProduct}::site/${siteId}` : '';

	let actors: string[] = [];
	return fetchAggResponse<
		KnowledgeCardTeamSearchRelevanceVariables,
		KnowledgeCardTeamSearchRelevanceType
	>({
		graphQuery: KnowledgeCardTeamSearchRelevanceQuery,
		variables: {
			teamQuery,
			orgId,
			siteId: siteARI,
		},
		operationName: KNOWLEDGE_CARD_TEAM_SEARCH_RELEVANCE_OPERATION_NAME,
	})
		.then(({ data, errors }) => {
			const id = data?.knowledgeDiscovery.searchTeam?.id;
			if (!errors && id) {
				teamResult = id;
			} else {
				throw new Error(NO_TEAM_MESSAGE);
			}
			return fetchAggResponse<KnowledgeCardTeamDataVariables, KnowledgeCardTeamDataType>({
				graphQuery: KnowledgeCardTeamDataQuery,
				variables: {
					teamId: teamResult,
					siteId: siteId,
				},
				operationName: KNOWLEDGE_CARD_TEAM_DATA_OPERATION_NAME,
			});
		})
		.then(({ data, errors }) => {
			if (!errors && data?.team.teamV2?.id) {
				teamData = data;
			} else {
				throw new Error(NO_TEAM_MESSAGE);
			}
			actors =
				teamData.team.teamV2?.members?.nodes
					?.map((node) => (node.member ? node.member.id : ''))
					.filter((member) => !!member) || [];
			return activityClient.fetchActivities(
				[100],
				{
					limit: 100,
					actors,
					rootContainerIds: [`ari:cloud:platform::site/${siteId}`],
				},
				['workedOn'],
				false,
			);
		})
		.then((data) => {
			if (data.workedOn && data.workedOn.length > 0) {
				activityResult = data.workedOn;
			}
			const filteredActivityResult = filterTeamActivities(activityResult || [], actors);
			const finalResult: NLPSearchTeamResultType = {
				team_metadata: {
					id: teamData?.team.teamV2?.id || '',
					name: teamData?.team.teamV2?.displayName || '',
					avatarUrl: teamData?.team.teamV2?.smallAvatarImageUrl || null,
					url: teamData?.team.teamV2?.id
						? `/people/team/${trimARI(teamData?.team.teamV2?.id)}`
						: null,
					memberCount: teamData?.team.teamV2?.members.nodes.length || 0,
					members: teamData?.team.teamV2?.members?.nodes
						? sanitizeAndMapTeamMembers(teamData.team.teamV2.members.nodes)
						: [],
					recentActivity: filteredActivityResult
						? sanitizeAndMapRecentWorkActivity(filteredActivityResult)
						: [],
				},
			};
			return {
				nlpSearch: {
					nlpResult: JSON.stringify(finalResult),
					uniqueSources: [],
					disclaimer: null,
					errorState: null,
					format: NLPSearchResultFormat.JSON,
				},
			};
		})
		.catch((error) => {
			if (error instanceof Object && 'message' in error && error.message === NO_TEAM_MESSAGE) {
				return {
					nlpSearch: {
						nlpResult: JSON.stringify(noTeamResult),
						errorState: null,
						disclaimer: null,
						format: NLPSearchResultFormat.JSON,
						uniqueSources: [],
					},
				};
			}

			return Promise.reject(error);
		});
};

type TeamSectionType = KnowledgeCardTeamSearchNodeType;

export const fetchTeamKnowledgeCardData = ({
	activityClient,
	teamQuery,
	orgId,
	tenantId,
}: TeamKnowledgeCardVariables): Promise<SearchAIAnswerQueryType> => {
	let teamResult: TeamSectionType | undefined;
	let activityResult: ActivitySectionType | undefined;

	const onError = (error: any) => {
		return Promise.reject(error);
	};

	try {
		let actors: string[] = [];
		return fetchAggResponse<KnowledgeCardTeamSearchVariables, KnowledgeCardTeamSearchType>({
			graphQuery: KnowledgeCardTeamSearchQuery,
			variables: {
				teamQuery,
				orgId: `ari:cloud:platform::org/${orgId}`,
				tenantId,
			},
			operationName: KNOWLEDGE_CARD_TEAM_SEARCH_OPERATION_NAME,
		})
			.then(({ data, errors }) => {
				if (
					!errors &&
					data?.team.teamSearchV2.nodes &&
					data.team.teamSearchV2.nodes.length > 0 &&
					data.team.teamSearchV2.nodes[0]
				) {
					teamResult = data.team.teamSearchV2.nodes[0];
				} else {
					throw new Error('No team found');
				}
				actors =
					teamResult?.team?.members?.nodes
						?.map((node) => (node.member ? node.member.id : ''))
						.filter((member) => !!member) || [];
				return activityClient.fetchActivities(
					[100],
					{
						limit: 100,
						actors,
						rootContainerIds: [`ari:cloud:platform::site/${tenantId}`],
					},
					['workedOn'],
					false,
				);
			}, onError)
			.then((data) => {
				if (data.workedOn && data.workedOn.length > 0) {
					activityResult = data.workedOn;
				}
				const filteredActivityResult = filterTeamActivities(activityResult || [], actors);
				const finalResult: NLPSearchTeamResultType = {
					team_metadata: {
						id: teamResult?.team?.id || '',
						name: teamResult?.team?.displayName || '',
						avatarUrl: teamResult?.team?.smallAvatarImageUrl || null,
						url: teamResult?.team?.id ? `/people/team/${trimARI(teamResult.team.id)}` : null,
						memberCount: teamResult?.memberCount || 0,
						members: teamResult?.team?.members?.nodes
							? sanitizeAndMapTeamMembers(teamResult.team.members.nodes)
							: [],
						recentActivity: filteredActivityResult
							? sanitizeAndMapRecentWorkActivity(filteredActivityResult)
							: [],
					},
				};
				return {
					nlpSearch: {
						nlpResult: JSON.stringify(finalResult),
						uniqueSources: [],
						disclaimer: null,
						errorState: null,
						format: NLPSearchResultFormat.JSON,
					},
				};
			}, onError);
	} catch (error) {
		return Promise.resolve({
			nlpSearch: {
				errorState: NLPSearchErrorState.NetworkError,
				disclaimer: null,
				format: NLPSearchResultFormat.JSON,
				uniqueSources: [],
			},
		});
	}
};

const sanitizeAndMapTeamMembers = (members: KnowledgeCardTeamSearchMemberNodeType[]) => {
	return members.reduce<NLPSearchWhoResultCollaboratorDataType[]>(function (result, memberNode) {
		if (!!memberNode.member) {
			return result.concat({
				name: memberNode.member.name || '',
				picture: memberNode.member.picture || null,
				title: memberNode.member.extendedProfile?.jobTitle || null,
				url: !!memberNode.member.id ? `/people/${trimARI(memberNode.member.id)}` : null,
				accountId: !!memberNode.member.id ? trimARI(memberNode.member.id) : null,
				department: memberNode.member.extendedProfile?.department || null,
				email: memberNode.member.email || null,
			});
		}
		return result;
	}, []);
};

const excludedEventTypes = ['commented', 'updated'];
export const filterTeamActivities = (
	activityResult: ActivityItem[],
	members: string[],
): ActivityItem[] => {
	const teamMemberSet = new Set(members);
	return activityResult.reduce<ActivityItem[]>(
		(filtered: ActivityItem[], activity: ActivityItem) => {
			const involvedTeamMembers = activity.contributors.filter((contributor) =>
				teamMemberSet.has(`ari:cloud:identity::user/${contributor.accountId}`),
			);
			if (involvedTeamMembers.length >= 1 && !excludedEventTypes.includes(activity.eventType)) {
				filtered.push({
					...activity,
					contributors: involvedTeamMembers,
				});
			}

			return filtered;
		},
		[],
	);
};
