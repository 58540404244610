// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { B300, B400, N0, N200, N600, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { READING_AIDS_DIALOG_WIDTH } from '../../../constants';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InfoLink = styled.a({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	color: `${token('color.text.accent.blue', B300)} !important`,
	textDecoration: 'underline',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerResultSection = styled.div<{ isReadingAids?: boolean; isExpanded?: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isReadingAids, isExpanded }) =>
		isReadingAids
			? {
					font: token('font.body.large'),
					color: token('color.text.accent.gray.bolder', N800),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					maxWidth: `calc(${READING_AIDS_DIALOG_WIDTH} - 40px)`,
					maxHeight: isExpanded ? 'fit-content' : '120px',
					overflowY: 'hidden',
				}
			: {
					font: token('font.body'),
					color: token('color.text.accent.gray', N600),
					overflowY: 'auto',
					width: '100%',
				},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerResultBlurSection = styled.div({
	background: `linear-gradient( 180deg, ${token(
		'utility.UNSAFE.transparent',
		'rgba(255, 255, 255, 0)',
	)} 0%, ${token('elevation.surface.overlay', N0)} 100% )`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `calc(${READING_AIDS_DIALOG_WIDTH} - 48px)`,
	display: 'flex',
	justifyContent: 'end',
	position: 'absolute',
	top: '138px',
	cursor: 'pointer',
	paddingBottom: token('space.050', '8px'),
	paddingTop: token('space.200', '16px'),
	color: token('color.text', '#172B4D'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const AIAnswerResultBlurSectionText = styled.div({
	display: 'flex',
	justifyContent: 'end',
	background: `linear-gradient( 90deg, ${token(
		'utility.UNSAFE.transparent',
		'rgba(255, 255, 255, 0)',
	)} 0%, ${token('elevation.surface.overlay', N0)} 100% )`,
	width: '75%',
	paddingRight: token('space.050', '4px'),
	font: token('font.body.large'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DisclaimerSectionWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginTop: token('space.075', '6px'),
	font: token('font.body.small'),
	color: token('color.text.subtlest', N200),
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DisclaimerWrapper = styled.div({
	display: 'flex',
	fontStyle: 'italic',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PermissionLabelWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		width: '16px',
		height: '16px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerSourcesWrapper = styled.div<{ isReadingAids?: boolean }>(
	{
		width: '100%',
		color: token('color.text.subtlest', N200),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		button: {
			marginTop: token('space.075', '6px'),
			maxWidth: 'fit-content',
			fontWeight: token('font.weight.regular'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isReadingAids }) =>
		isReadingAids
			? `
    margin-top: ${token('space.100', '8px')};
    h2 ~ a {
      text-decoration: none !important;
    }
    `
			: `
    margin-top: ${token('space.150', '12px')};
    `,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerSource = styled.div<{ isReadingAids?: boolean }>(
	{
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isReadingAids }) =>
		isReadingAids
			? `
    &:hover,
    &:focus-within {
      background-color: ${token('elevation.surface.hovered', '#F1F2F4')};
    }
    &:active {
      background-color: ${token('elevation.surface.pressed', '#DCDFE4')};
    }
      border-radius: 3px;
      padding: ${token('space.050', '4px')};
    `
			: `
      padding:
        ${token('space.050', '4px')}
        ${token('space.0', '0px')}
        ${token('space.050', '4px')}
        ${token('space.025', '2px')};
      &&:last-of-type {
        padding-bottom: ${token('space.0', '0px')};
      }
      `,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerSourceTitle = styled.span({
	display: 'flex',
	flexGrow: 2,
	maxWidth: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		minWidth: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		a: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			textDecorationColor: `${token('color.text.accent.blue', B400)} !important`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'span:first-of-type': {
				marginRight: token('space.050', '4px'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				svg: {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
					height: '16px !important',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
					width: '16px !important',
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'span:last-of-type': {
				font: token('font.heading.xxsmall'),
				color: token('color.text.accent.blue', B400),
			},
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerSourceMetadata = styled.span({
	display: 'flex',
	alignItems: 'center',
	maxWidth: '50%',
	color: token('color.text.subtlest', N200),
	font: token('font.body.small'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		svg: {
			height: '16px',
			width: '16px',
			margin: token('space.025', '2px'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerSourceSeparator = styled.span({
	margin: `${token('space.0', '0px')} ${token('space.050', '4px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerSourceSpaceName = styled.span({
	minWidth: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		display: 'inherit',
		cursor: 'pointer',
		color: token('color.text.subtlest', N200),
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'&:hover': {
			color: token('color.text.subtlest', N200),
		},
		'&:visited': {
			color: token('color.text.subtlest', N200),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerSourceExpandCollapseLink = styled.div({
	font: token('font.body.small'),
	paddingBottom: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerCloseLabelWrapper = styled.div({
	display: 'flex',
	gap: token('space.050', '4px'),
	color: token('color.text.subtlest', N200),
	font: token('font.body.small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		cursor: 'pointer',
	},
});

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsTitleAndMetadata = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `calc(${READING_AIDS_DIALOG_WIDTH} - 72px)`,
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	flexShrink: 1,
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		textDecoration: 'none !important',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsSourceTitle = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `calc(${READING_AIDS_DIALOG_WIDTH} - 72px)`,
	paddingLeft: token('space.100', '8px'),
	font: token('font.body'),
	color: token('color.text.accent.gray.bolder', N800),
	textAlign: 'left',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsSourceMetadata = styled.div({
	display: 'inline-flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `calc(${READING_AIDS_DIALOG_WIDTH} - 72px)`,
	paddingLeft: token('space.100', '8px'),
	font: token('font.body.small'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	color: token('color.text.subtlest', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsIconContainer = styled.div({
	height: '24px',
	width: '24px',
});
