import React from 'react';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { type RestrictionOptionType } from '../types';

const optionWrapperStyles = xcss({
	color: 'color.text',
	font: token('font.body'),
});

export const restrictionOptionFormatter = <
	Option extends RestrictionOptionType = RestrictionOptionType,
>(
	option: Option,
) => {
	return (
		<Box xcss={optionWrapperStyles}>
			<Flex alignItems="center" gap="space.100">
				{option.icon}
				{option.label}
			</Flex>
		</Box>
	);
};
