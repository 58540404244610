import React, { memo, useEffect } from 'react';

import { Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { useAIAnswerContext } from '../ai-answer-dialog/ai-answer-context';
import { type NLPSearchTeamResultDataType } from '../ai-answer-dialog/types';
import { getTypedJSONResultData } from '../ai-answer-dialog/utils/getTypedJSONResultData';
import { useAnalytics } from '../common/controller/analytics';

import { onKnowledgeCardShown } from './analytics';
import { getEditDistanceRatio } from './gql/hydrate-team-card';
import { KnowledgeCardsBody } from './knowledge-cards-body';
import { KnowledgeCardsHeader } from './knowledge-cards-header';
import { SeparatorLine } from './separator-line';
import { KnowledgeCardsIntentType } from './types';

const CardContainerStyles = xcss({
	boxShadow: 'elevation.shadow.overflow',
	borderRadius: token('border.radius.100'),
});

export const TeamKnowledgeCard = memo(() => {
	const {
		commonAttributes,
		userDetails: { id: currentUserId },
	} = useAIAnswerContext();
	const [fireAnalyticsEvent] = useAnalytics();
	const { answerString, query } = commonAttributes;
	const data = getTypedJSONResultData(answerString).parsedResult as NLPSearchTeamResultDataType;
	const editDistanceRatio = getEditDistanceRatio(query, data.name);

	useEffect(() => {
		if (data.id) {
			fireAnalyticsEvent(
				onKnowledgeCardShown({
					...commonAttributes,
					cardType: KnowledgeCardsIntentType.TEAM,
					extraAttributes: {
						editDistance: editDistanceRatio,
					},
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return data.id ? (
		<Stack xcss={CardContainerStyles}>
			<KnowledgeCardsHeader
				type={KnowledgeCardsIntentType.TEAM}
				name={data.name}
				avatarUrl={data.avatarUrl}
				profileUrl={data.url}
			/>
			<SeparatorLine />
			<KnowledgeCardsBody
				type={KnowledgeCardsIntentType.TEAM}
				currentUserId={currentUserId || ''}
				recentActivity={data.recentActivity}
				recommendedUsers={data.members}
				profileUrl={data.url || ''}
				viewAllUrl={data.url || ''}
			/>
		</Stack>
	) : null;
});
