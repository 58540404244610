/* eslint-disable @atlaskit/ui-styling-standard/no-nested-selectors */
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/* eslint-disable @atlaskit/ui-styling-standard/no-styled */
/* eslint-disable  @atlaskit/ui-styling-standard/no-exported-styles */
/* eslint-disable @atlaskit/ui-styling-standard/no-unsafe-values */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { N0, N200, N60A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const AIAnswerSearchBorder = styled.div<{
	isLoading: boolean;
	isStreaming: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ isLoading, isStreaming }) => ({
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',
	borderRadius: `${token('space.050', '4px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: '0',
	boxSizing: 'border-box',
	overflow: 'hidden',
	zIndex: 3,
	boxShadow: token(
		'elevation.shadow.overlay',
		`0 ${token('space.050', '4px')} ${token('space.100', '8px')} ${token(
			'space.negative.025',
			'-2px',
		)} ${N60A}, 0 0 1px ${N60A}`,
	),
	'&::before': {
		content: "''",
		width: '1000vw',
		height: '1000vh',
		left: 'calc(-500vw + 50%)',
		top: 'calc(-500vh + 50%)',
		position: 'absolute',
		zIndex: -2,
		borderRadius: token('space.050', '4px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		background:
			isLoading || isStreaming
				? 'conic-gradient( from var(--gradient-angle, 45deg), #BF63F3, #FFA900 25%, #0469FF 67%, #0065FF, #BF63F3)'
				: token('elevation.surface.overlay', N0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		animation: isLoading || isStreaming ? 'rotate 3s infinite linear' : 'rotate-end 3s 1 linear',
		filter: 'blur(25px)',
	},
	'&::after': {
		content: "''",
		width: `100% - ${token('space.050', '4px')}`,
		height: `100% - ${token('space.050', '4px')}`,
		inset: '1px',
		position: 'absolute',
		zIndex: -1,
		borderRadius: token('space.050', '4px'),
		backgroundColor: token('elevation.surface.overlay', N0),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes rotate': {
		from: {
			transform: 'rotate(0deg)',
		},
		to: {
			transform: 'rotate(360deg)',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes rotate-end': {
		from: {
			transform: 'rotate(0deg)',
			background:
				'conic-gradient( from var(--gradient-angle, 45deg), #BF63F3, #FFA900 25%, #0469FF 67%, #0065FF, #BF63F3)',
		},
		to: {
			transform: 'rotate(360deg)',
			background:
				'conic-gradient( from var(--gradient-angle, 45deg), #BF63F3, #FFA900 25%, #0469FF 67%, #0065FF, #BF63F3)',
		},
	},
}));
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerLoadingSkeletonWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginTop: token('space.200', '16px'),
	gap: token('space.100', '8px'),
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsLoadingPill = styled.div<{
	width?: string;
	height?: string;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ width, height }) => ({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: width ? width : '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: height ? height : '16px',
	borderRadius: '8px',
	background: `linear-gradient(to right, ${token('color.background.neutral', 'rgba(240, 241, 244, 1)')}, ${token('color.background.input.pressed', 'rgba(252, 252, 252, 1)')})`,
	animation: 'shimmer 1.5s linear infinite alternate',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes shimmer': {
		from: {
			background: `linear-gradient(to right, ${token('color.background.neutral', 'rgba(240, 241, 244, 1)')}, ${token('color.background.input.pressed', 'rgba(252, 252, 252, 1)')})`,
		},
		to: {
			background: `linear-gradient(to right, ${token('color.background.neutral', 'rgba(240, 241, 244, 1)')}, ${token('color.background.input.pressed', 'rgba(252, 252, 252, 1)')})`,
		},
	},
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const GeneratingContainer = styled.div({
	display: 'flex',
	height: '92px',
	boxSizing: 'border-box',
	padding: `${token('space.300', '24px')} ${token('space.300', '24px')} 0px ${token('space.300', '24px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const GeneratingText = styled.div({
	marginLeft: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', N200),
	font: token('font.body'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ToggleButton = styled.div({
	cursor: 'pointer',
	display: 'block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ButtonIcon = styled.div({
	display: 'block',
	transform: 'rotate(135deg)',
});

export const Blur = styled.div({
	background: `linear-gradient( 180deg, ${token(
		'utility.UNSAFE.transparent',
		'rgba(255, 255, 255, 0)',
	)} 0%, ${token('elevation.surface.overlay', N0)} 100% )`,
	bottom: '0',
	display: 'block',
	height: token('space.500', '40px'),
	margin: '0 1px',
	position: 'sticky',
});

export const AISourcesWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: token('space.100'),
	padding: `0 ${token('space.300')} 0 ${token('space.300')}`,
});
