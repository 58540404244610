import React, {
	type ComponentType,
	memo,
	type MouseEvent,
	type PropsWithChildren,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import isEqual from 'lodash/isEqual';
import { useIntl } from 'react-intl-next';

import { type AnalyticsEventPayload, useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import type { ProviderProps } from '@atlaskit/smart-card';
import {
	AIEventsInstrumentationProvider,
	useAIEventsInstrumentation,
} from '@atlassian/ai-analytics';
import { useActivityClient } from '@atlassian/recent-work-client';
import {
	deleteSessionItem,
	getSessionItem,
	SearchDialog,
	setSessionItem,
} from '@atlassian/search-dialog';

import { useSessionId } from '../common/controller/analytics/helpers';
import { AiBrand, CardTemplate, DEFAULT_GAS_CHANNEL, QueryIntent } from '../constants';
import { ErrorBoundary } from '../error-boundary';
import { KnowledgeCard } from '../knowledge-cards';
import { fetchPersonKnowledgeCardData } from '../knowledge-cards/gql/hydrate-people-card';
import {
	fetchTeamKnowledgeCardData,
	fetchTeamKnowledgeCardDataWithRanking,
} from '../knowledge-cards/gql/hydrate-team-card';
import { sha256Hash } from '../utils';

import { AIAnswerContext, type AIAnswerContextType } from './ai-answer-context';
import { AIAnswerDefaultLoadingWrapper } from './ai-answer-loading/styled';
import {
	onAIAnswerDialogDroppedOff,
	onAIAnswerDialogQueryIntent,
	onAIAnswerDialogUnmount,
	onAIAnswerDialogViewed,
	onAIAnswerNotShown,
	onAIAnswerResultShown,
} from './analytics';
import { DefinitionAnswerCard } from './definition-answer-card';
import { aiAnswerDialogMetric, MarkAIAnswerDialogMetricEnd } from './metrics';
import { LegacySmartAnswerCard, SmartAnswerCard } from './smart-answer-card';
import {
	type AIAnswerCacheType,
	type AIAnswerQueryFunctionType,
	type Callbacks,
	type commonAttributesType,
	NLPSearchCustomDefinitionType,
	NLPSearchErrorState,
	type NLPSearchType,
	type parsedFiltersType,
	type ReadingAidsOptions as ReadingAidsOptionsType,
} from './types';
import { fetchAIAnswer } from './utils/fetchAIAnswer';
import { getKnowledgeCardResultCount, getNumAddtlWhoMatches } from './utils/knowledgecard-utils';

const searchAiDialogContainerStyles = xcss({
	position: 'relative', // this is for absolute positioned children
});

export const SEARCH_AI_ANSWER_CACHE_KEY = 'atlassian.search-dialog.ai-answer-component';

export const DEFAULT_EMPTY_NLP_SEARCH_STATE: NLPSearchType = {
	errorState: NLPSearchErrorState.Default,
	format: null,
	disclaimer: null,
	nlpResults: [],
	uniqueSources: [],
};

export type SearchAIDialogProps = {
	query: string;
	answerLoadingRef?: React.MutableRefObject<boolean>;
	setQuery: (value: string) => void;
	additionalQueryContext?: string;
	cloudId: string;
	orgId?: string;
	getAIAnswer: AIAnswerQueryFunctionType;
	onNavigate: () => void;
	onClose?: () => void;
	advancedSearchUrl?: string;
	searchSessionId: string;
	userDetails: {
		name?: string;
		id?: string;
	};
	wrapper?: ComponentType<PropsWithChildren<any>>;
	loadingWrapper?: ComponentType<PropsWithChildren<any>>;
	source?: string; // Not to be confused with the ai answer sources, this is the source of where package is imported, used for analytics
	isReadingAids?: boolean;
	readingAidsOptions?: ReadingAidsOptionsType;
	extraAnalyticsAttributes?: { [key: string]: any };
	cardProviderProps?: Omit<ProviderProps, 'children'>;
	baseQuery?: string; // used when the query passed in has added pretext (e.g. "what is")
	onLoad?: () => void; // callback to fire when the dialog is loaded (used for popup resize)
	followUpsEnabled?: boolean;
	parsedFilters?: parsedFiltersType;
	edition?: string;
	smartAnswersRoute?: QueryIntent;
	cardTemplate?: CardTemplate;
	sourceProduct?: string; // Product from which source is located e.g. Confluence or Jira
	sourceSubProduct?: string; // Subproduct from which source is located, used for Jira
	workspaceId?: string;
	brand?: AiBrand;
	closeParentDialog?: () => void;
	callbacks?: Callbacks;
	isAdminHubAIEnabled?: boolean;
	readingAidsStreamingExperimentEnabled?: boolean;
};

const SearchAIDialogComponent = ({
	query,
	setQuery,
	additionalQueryContext,
	cloudId,
	orgId,
	getAIAnswer,
	onNavigate,
	onClose,
	advancedSearchUrl,
	searchSessionId,
	userDetails,
	wrapper,
	loadingWrapper,
	source = 'unknown',
	isReadingAids = false,
	readingAidsOptions,
	extraAnalyticsAttributes = {},
	cardProviderProps,
	baseQuery = '',
	onLoad,
	followUpsEnabled,
	parsedFilters,
	answerLoadingRef,
	smartAnswersRoute,
	cardTemplate = CardTemplate.DEFAULT,
	sourceProduct,
	workspaceId,
	brand = AiBrand.AI,
	closeParentDialog,
	callbacks,
	isAdminHubAIEnabled,
}: SearchAIDialogProps) => {
	const intl = useIntl();
	const locale = intl.locale;
	const [loading, setLoading] = useState<boolean | undefined>(undefined);

	const partialResponseAbortControllerRef = useRef<AbortController>();
	const [partialResponse, setPartialResponse] = useState<AIAnswerContextType['partialResponse']>();

	const [answerStreamed, setAnswerStreamed] = useState<boolean>(false);

	const [liked, setLiked] = useState<boolean | undefined>(undefined);
	const [likesDisabled, setLikesDisabled] = useState<boolean>(false);
	const [initialReported, setInitialReported] = useState<boolean | undefined>(undefined);
	const [nlpSearch, setData] = useState<NLPSearchType>(DEFAULT_EMPTY_NLP_SEARCH_STATE);
	const filtersRef = useRef(parsedFilters);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const resultRef = useRef<HTMLSpanElement>(null);
	const initialLoadTs = useRef<number>(Date.now()).current;
	const loadedTsRef = useRef<number>();
	const markLoadedTs = () => {
		loadedTsRef.current = Date.now();
		return loadedTsRef.current - initialLoadTs;
	};

	const { trackAIInteractionInit, trackAIInteractionDismiss } = useAIEventsInstrumentation();
	const sessionId = useSessionId({
		userId: userDetails.id || '',
		cloudId,
		source,
	});

	const activityClient = useActivityClient('v3', 'people');
	const showKnowledgeCardsv2 = fg('ai_topics_knowledge_cards_v2');
	const useKnowledgeDiscoveryTeamSearch = fg('ai_use_kd_team_search');
	const renderCardByExperience = fg('kd_smart_answers_render_by_experience');

	const isAnswered = loading !== undefined && !loading && nlpSearch.errorState === null;
	const hasError =
		loading !== undefined &&
		!!nlpSearch.errorState &&
		nlpSearch.errorState !== NLPSearchErrorState.Default;
	const answerString = nlpSearch.nlpResults?.length
		? nlpSearch.nlpResults[0].nlpResult
		: nlpSearch.nlpResult || '';
	const answerFormat = nlpSearch.format;
	const followUps = nlpSearch.nlpFollowUpResults?.followUps;
	const sources = nlpSearch.uniqueSources;
	const disclaimer = nlpSearch.disclaimer;
	const answerEditor = nlpSearch.nlpResultEditor;
	const extraAPIAnalyticsAttributes = nlpSearch.extraAPIAnalyticsAttributes;

	const answerCardType = isReadingAids
		? 'definition'
		: smartAnswersRoute === QueryIntent.PERSON
			? 'people'
			: smartAnswersRoute === QueryIntent.TEAM
				? 'team'
				: 'SAIN';

	const commonAttributes: commonAttributesType = useMemo(
		() => ({
			cloudId,
			orgId: orgId ?? '',
			queryHash: sha256Hash(query),
			baseQueryHash: sha256Hash(baseQuery),
			query,
			appliedFilters: parsedFilters,
			searchSessionId,
			source,
			answerString,
			answerLength: answerString.length,
			answerFormat,
			answerCardType,
			sources,
			followUps: followUps || [],
			errorState: nlpSearch.errorState,
			errorCode: nlpSearch.errorCode,
			extraAttributes: {
				featureGates: {
					readingAidsStreamingEnabled: fg('reading_aids_streaming'),
					precomputedDefinitionsEnabled: fg('kd_enable_precomputed_definitions'),
				},
				...extraAnalyticsAttributes,
				...extraAPIAnalyticsAttributes,
			},
			apiSource: 'assistance-service',
			workspaceId: workspaceId ?? '',
			sessionId,
			brand,
		}),
		[
			cloudId,
			orgId,
			query,
			baseQuery,
			parsedFilters,
			searchSessionId,
			source,
			answerString,
			answerFormat,
			nlpSearch.errorState,
			nlpSearch.errorCode,
			extraAnalyticsAttributes,
			extraAPIAnalyticsAttributes,
			workspaceId,
			sessionId,
			brand,
			answerCardType,
			followUps,
			sources,
		],
	);

	const fireAnalyticsEvent = useCallback(
		(payload: AnalyticsEventPayload) => {
			createAnalyticsEvent(payload).fire(DEFAULT_GAS_CHANNEL);
		},
		[createAnalyticsEvent],
	);

	const onNavigateWithMouseEvent = useCallback(
		(event: MouseEvent) => {
			if (!event.ctrlKey && !event.metaKey) {
				onNavigate();
			}
		},
		[onNavigate],
	);

	const getAIAnswerFn = useCallback(() => {
		if (showKnowledgeCardsv2) {
			switch (smartAnswersRoute) {
				case QueryIntent.PERSON:
					return (_args: any) =>
						fetchPersonKnowledgeCardData({
							tenantId: cloudId,
							personQuery: query,
							principalUserId: userDetails.id || '',
							intl,
						});
				case QueryIntent.TEAM:
					return (_args: any) =>
						useKnowledgeDiscoveryTeamSearch
							? fetchTeamKnowledgeCardDataWithRanking({
									activityClient,
									orgId: orgId ?? '',
									siteId: cloudId,
									teamQuery: query,
									sourceProduct,
								})
							: fetchTeamKnowledgeCardData({
									activityClient,
									orgId: orgId ?? '',
									tenantId: cloudId,
									teamQuery: query,
								});
				default:
				// fallthrough to getAIAnswer
			}
		}
		return getAIAnswer;
	}, [
		getAIAnswer,
		smartAnswersRoute,
		query,
		orgId,
		cloudId,
		activityClient,
		showKnowledgeCardsv2,
		useKnowledgeDiscoveryTeamSearch,
		userDetails.id,
		intl,
		sourceProduct,
	]);

	const updateContextCache = useCallback(
		({
			collapsed,
			liked,
			reported,
			recentWorkListExpanded,
			sourcesExpanded,
			fromFollowUp,
		}: Omit<AIAnswerCacheType, 'query' | 'additionalContext' | 'data'>) => {
			const currentCache = JSON.parse(
				getSessionItem(SEARCH_AI_ANSWER_CACHE_KEY) || '{}',
			) as AIAnswerCacheType;
			setSessionItem(
				SEARCH_AI_ANSWER_CACHE_KEY,
				JSON.stringify({
					query: query,
					additionalContext: additionalQueryContext || '',
					data: nlpSearch,
					collapsed: collapsed === undefined ? currentCache.collapsed : collapsed,
					liked: liked === undefined ? currentCache.liked : liked,
					reported: reported === undefined ? currentCache.reported : reported,
					fromFollowUp: fromFollowUp === undefined ? currentCache.fromFollowUp : fromFollowUp,
					recentWorkListExpanded:
						recentWorkListExpanded === undefined
							? currentCache.recentWorkListExpanded
							: recentWorkListExpanded,
					sourcesExpanded:
						sourcesExpanded === undefined ? currentCache.sourcesExpanded : sourcesExpanded,
				}),
			);
		},
		[query, additionalQueryContext, nlpSearch],
	);

	useEffect(() => {
		if (answerLoadingRef) {
			answerLoadingRef.current = !!loading;
		}
	}, [loading, answerLoadingRef]);

	useEffect(() => {
		aiAnswerDialogMetric.start();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	useEffect(() => {
		let observer: IntersectionObserver | undefined;
		if (!loading && (isAnswered || hasError)) {
			observer = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					fireAnalyticsEvent(
						onAIAnswerDialogViewed({
							...commonAttributes,
						}),
					);
					observer?.disconnect();
				}
			});

			if (resultRef.current) {
				observer.observe(resultRef.current);
			}
		}
		return () => {
			observer?.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	useEffect(() => {
		const cached = JSON.parse(
			getSessionItem(SEARCH_AI_ANSWER_CACHE_KEY) || '{}',
		) as AIAnswerCacheType;
		if (cached?.query === query && cached?.additionalContext === additionalQueryContext) {
			setData(cached.data);
			if (cached.liked !== undefined) {
				setLiked(cached.liked);
				// if a like is cached, disable likes
				setLikesDisabled(true);
			}
			if (cached.reported !== undefined) {
				setInitialReported(cached.reported);
			}
			setLoading(false);
		} else {
			deleteSessionItem(SEARCH_AI_ANSWER_CACHE_KEY);
			setLiked(undefined);
			setLikesDisabled(false);
			fetchAIAnswer({
				cloudId,
				locale,
				query,
				additional_context: additionalQueryContext,
				source,
				followUpsEnabled: followUpsEnabled && !cached.fromFollowUp,
				getAIAnswer: getAIAnswerFn(),
				setLoading,
				partialResponse,
				partialResponseAbortControllerRef,
				setPartialResponse,
				setData,
				setAnswerStreamed,
				parsedFilters,
				isReadingAids,
				fireAnalyticsEvent,
			});
		}
		filtersRef.current = parsedFilters;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query, additionalQueryContext]);

	useEffect(() => {
		if (!isEqual(filtersRef.current, parsedFilters)) {
			deleteSessionItem(SEARCH_AI_ANSWER_CACHE_KEY);
			setLiked(undefined);
			setLikesDisabled(false);
			fetchAIAnswer({
				cloudId,
				locale,
				query,
				source,
				followUpsEnabled,
				getAIAnswer: getAIAnswerFn(),
				setLoading,
				partialResponse,
				partialResponseAbortControllerRef,
				setPartialResponse,
				setData,
				setAnswerStreamed,
				parsedFilters,
				isReadingAids,
				fireAnalyticsEvent,
			});
		}
		filtersRef.current = parsedFilters;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedFilters]);

	useEffect(() => {
		if (isAnswered) {
			// product wants number of additional who matches returned by backend when who question asked
			const numAddtlWhoMatches = getNumAddtlWhoMatches({
				answerString,
				answerFormat,
			});

			// if a person or team query returns no data don't log result shown
			if (
				showKnowledgeCardsv2 &&
				(smartAnswersRoute === QueryIntent.PERSON || smartAnswersRoute === QueryIntent.TEAM) &&
				getKnowledgeCardResultCount({ answerString }) < 1
			) {
				markLoadedTs();
				return;
			}

			fireAnalyticsEvent(
				onAIAnswerResultShown({
					...commonAttributes,
					isStreamed: answerStreamed,
					loadTime: markLoadedTs(),
					extraAttributes: {
						...commonAttributes.extraAttributes,
						numAddtlWhoMatches: numAddtlWhoMatches,
						isCuratedDefinition: !!answerEditor,
						isPrecomputedDefinition:
							nlpSearch.customDefinitionType === NLPSearchCustomDefinitionType.PRECOMPUTED,
					},
				}),
			);
		} else if (hasError) {
			fireAnalyticsEvent(
				onAIAnswerNotShown({
					...commonAttributes,
					isStreamed: answerStreamed,
					loadTime: markLoadedTs(),
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, isAnswered]);

	useEffect(() => {
		trackAIInteractionInit();
		fireAnalyticsEvent(onAIAnswerDialogQueryIntent(commonAttributes));
		return () => {
			fireAnalyticsEvent(
				onAIAnswerDialogUnmount({
					...commonAttributes,
					isReadingAids,
					sourceProduct,
					dwellTime: loadedTsRef.current ? Date.now() - loadedTsRef.current : undefined,
					loadTime: loadedTsRef.current
						? loadedTsRef.current - initialLoadTs
						: Date.now() - initialLoadTs,
				}),
			);
			if (!loadedTsRef.current) {
				fireAnalyticsEvent(
					onAIAnswerDialogDroppedOff({
						...commonAttributes,
					}),
				);
				trackAIInteractionDismiss();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		if (onLoad && !loading && (isAnswered || hasError)) {
			onLoad();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, isAnswered, hasError]);

	const AIDialogWrapper = wrapper ? wrapper : SearchDialog;
	const AILoadingWrapper = loadingWrapper ? loadingWrapper : AIAnswerDefaultLoadingWrapper;

	const renderDefinition = () => <DefinitionAnswerCard />;

	// SAIN in FPS
	const renderSearchPageSmartAnswer = () => {
		return (
			<AIDialogWrapper>
				<SmartAnswerCard />
			</AIDialogWrapper>
		);
	};

	const renderSearchPageKnowledgeCard = () => (
		<AIDialogWrapper>
			<KnowledgeCard />
		</AIDialogWrapper>
	);

	// Quick search and advanced search
	const renderDefaultSmartAnswer = () => {
		return (
			<LegacySmartAnswerCard LoadingWrapper={AILoadingWrapper} DialogWrapper={AIDialogWrapper} />
		);
	};

	const renderDialog = () => {
		switch (cardTemplate) {
			case CardTemplate.SMART_ANSWER:
			case CardTemplate.RIGHT_PANEL:
				return renderSearchPageSmartAnswer(); // branch out SAIN in FPS
			default:
				return isReadingAids ? renderDefinition() : renderDefaultSmartAnswer();
		}
	};

	const renderExperience = () => {
		switch (smartAnswersRoute) {
			case QueryIntent.NATURAL_LANGUAGE_QUERY:
			case QueryIntent.KEYWORD_OR_ACRONYM:
				return renderSearchPageSmartAnswer();
			case QueryIntent.PERSON:
			case QueryIntent.TEAM:
				return renderSearchPageKnowledgeCard();
			default:
				return isReadingAids ? renderDefinition() : renderDefaultSmartAnswer();
		}
	};

	return (
		<AIAnswerContext.Provider
			value={{
				loading,
				partialResponse,
				isAnswered,
				hasError,
				advancedSearchUrl,
				userDetails,
				disclaimer,
				onNavigate: onNavigateWithMouseEvent,
				onClose,
				updateContextCache,
				fireAnalyticsEvent,
				setQuery,
				setData,
				commonAttributes,
				liked,
				setLiked,
				likesDisabled,
				setLikesDisabled,
				initialReported,
				isReadingAids,
				readingAidsOptions,
				cardProviderProps,
				baseQuery,
				cardTemplate,
				smartAnswersRoute,
				brand,
				answerEditor,
				closeParentDialog,
				additionalQueryContext,
				callbacks,
				isAdminHubAIEnabled,
			}}
		>
			<Box xcss={searchAiDialogContainerStyles} ref={resultRef}>
				{renderCardByExperience ? renderExperience() : renderDialog()}
			</Box>
			{loading === false && (isAnswered || hasError) && <MarkAIAnswerDialogMetricEnd />}
		</AIAnswerContext.Provider>
	);
};

export const SearchAIDialog = memo((props: SearchAIDialogProps) => {
	const { isReadingAids, source, sourceProduct, sourceSubProduct, smartAnswersRoute } = props;
	return (
		<ErrorBoundary {...props}>
			<AIEventsInstrumentationProvider
				config={{
					product: sourceProduct ?? 'unknown',
					subproduct: sourceSubProduct ?? 'unknown',
					aiFeatureName: isReadingAids ? 'aiDefinitions' : 'smartAnswers',
					aiExperienceName: isReadingAids ? undefined : smartAnswersRoute,
					proactiveGeneratedAI: 0,
					userGeneratedAI: 1,
					isAIFeature: 1,
					source: source,
					channel: DEFAULT_GAS_CHANNEL,
				}}
			>
				<SearchAIDialogComponent {...props} />
			</AIEventsInstrumentationProvider>
		</ErrorBoundary>
	);
});
