import React, { useCallback, useMemo } from 'react';

import { SmartCardProvider as Provider } from '@atlaskit/link-provider';
import Lozenge from '@atlaskit/lozenge';
import { Pressable, xcss } from '@atlaskit/primitives';
import { HoverCard } from '@atlaskit/smart-card/hover-card';
import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import type { NLPSearchResultSource } from '../../../../ai-answer-dialog/types';
import { extractUrlHostname } from '../../../../utils';
import { useAIAnswerContext } from '../../../ai-answer-context';
import { onAIAnswerSourceClick } from '../../../analytics';

const convertColorAppearance = (color: string) => {
	switch (color) {
		case 'blue':
			return 'inprogress';
		case 'green':
			return 'success';
		case 'red':
			return 'removed';
		case 'yellow':
			return 'moved';
		case 'purple':
			return 'new';
		default:
			return 'default';
	}
};

const citationStyles = xcss({
	border: 'none',
	background: 'none',
	padding: 'space.0',
	':hover': {
		cursor: 'pointer',
	},
});

type StatusCitationProps = {
	localId: string;
	text: string;
	color?: string;
};

export const StatusCitation = ({ localId, text, color = 'blue' }: StatusCitationProps) => {
	const { commonAttributes, fireAnalyticsEvent } = useAIAnswerContext();
	const sources = commonAttributes.sources;
	const { trackAIResultAction } = useAIEventsInstrumentation();

	const { source, idx } = useMemo(() => {
		if (!localId.includes('citation')) {
			return {};
		}

		const idx = parseInt(localId.split('-')?.[1]);
		return {
			source: sources[idx - 1],
			idx,
		};
	}, [localId, sources]);

	const handleClick = useCallback(
		(source: NLPSearchResultSource, idx: number) => {
			fireAnalyticsEvent(
				onAIAnswerSourceClick({
					sourceIndex: idx,
					isCitation: true,
					hostname: extractUrlHostname(source.url),
					...commonAttributes,
				}),
			);
			trackAIResultAction('sourceLinkClicked');
			window.open(source.url, '_blank', 'noopener, noreferrer');
		},
		[commonAttributes, fireAnalyticsEvent, trackAIResultAction],
	);

	if (!!source) {
		return (
			<Provider>
				<HoverCard url={source.url}>
					<Pressable
						xcss={citationStyles}
						onClick={() => {
							handleClick(source, idx);
						}}
					>
						<Lozenge appearance="inprogress">{idx}</Lozenge>
					</Pressable>
				</HoverCard>
			</Provider>
		);
	}

	// Standard lozenge for status nodes
	return <Lozenge appearance={convertColorAppearance(color)}>{text}</Lozenge>;
};
