// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { N200, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsOptionsWrapper = styled.span({
	zIndex: 1,
	right: 0,
	display: 'flex',
	paddingTop: token('space.250', '20px'),
	paddingRight: token('space.300', '24px'),
	minHeight: token('space.400', '32px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIOptionsMenuWrapper = styled.div({
	display: 'flex',
	paddingTop: token('space.200', '16px'),
	paddingBottom: token('space.200', '16px'),
	gap: token('space.250', '20px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIOptionsMenuOptionWrapper = styled.div({
	paddingLeft: token('space.150', '12px'),
	paddingRight: token('space.075', '6px'),
	width: '320px',
	display: 'flex',
	flexDirection: 'row',
	gap: token('space.250', '20px'),
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIOptionsMenuOptionText = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.050', '4px'),
	width: '244px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIOptionsMenuOptionHeader = styled.div({
	font: token('font.body'),
	color: token('color.text', N800),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIOptionsMenuOptionDescription = styled.div({
	font: token('font.body.small'),
	color: token('color.text.subtlest', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIOptionsMenuOptionToggleWrapper = styled.div({
	display: 'flex',
	width: 'fit-content',
});
