import React, { Fragment, useMemo, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { FormattedMessage, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { B300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { useAIAnswerContext } from '../../../../ai-answer-dialog/ai-answer-context';
import { NLPSearchErrorState } from '../../../../ai-answer-dialog/types';
import { AiBrand } from '../../../../constants';
import {
	ATLASSIAN_ACCEPTABLE_USE_POLICY_LINK,
	ATLASSIAN_AI_TROUBLESHOOT_LINK,
} from '../../common/constants';
import { EditDefinitionModal } from '../../curated/edit-definition/edit-definition-modal';

import { messages } from './messages';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
const InfoLink = styled.a({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	color: `${token('color.text.accent.blue', B300)} !important`,
	textDecoration: 'underline',
});

const ErrorPaddingStyles = xcss({
	paddingBottom: 'space.100',
});

const ErrorMessageStyles = xcss({
	font: token('font.body'),
	color: 'color.text',
});

export const DefinitionError = () => {
	const { formatMessage } = useIntl();
	const {
		brand,
		commonAttributes: { errorState },
		readingAidsOptions,
	} = useAIAnswerContext();

	const { definitionCurationOptions } = readingAidsOptions || {};
	const createDefinitionEnabled = !!definitionCurationOptions;

	const [showEditDefinitionModal, setShowEditDefinitionModal] = useState(false);

	const showAddDefinitionButton =
		createDefinitionEnabled &&
		(errorState === NLPSearchErrorState.NoAnswer ||
			errorState === NLPSearchErrorState.NoAnswerKeywords ||
			errorState === NLPSearchErrorState.NoAnswerSearchResults ||
			errorState === NLPSearchErrorState.NoAnswerHydration ||
			errorState === NLPSearchErrorState.NoAnswerRelevantContent ||
			errorState === NLPSearchErrorState.Default);

	const errorMessage = useMemo(() => {
		switch (errorState) {
			case NLPSearchErrorState.AcceptableUseViolation:
				return (
					<FormattedMessage
						{...messages.definition_no_answer_acceptable_use_error}
						values={{
							acceptableUseLink: (
								<InfoLink href={ATLASSIAN_ACCEPTABLE_USE_POLICY_LINK} target={'_blank'}>
									{formatMessage(messages.definition_no_answer_acceptable_use_link)}
								</InfoLink>
							),
							brand: brand === AiBrand.AI ? 'Atlassian Intelligence' : 'Rovo',
						}}
					/>
				);
			case NLPSearchErrorState.NetworkError:
			case NLPSearchErrorState.NoAnswerOpenAIResponseError:
			case NLPSearchErrorState.AIDisabled:
				return (
					<FormattedMessage
						{...messages.definition_no_answer_network_error}
						values={{
							brand: brand === AiBrand.AI ? 'Atlassian Intelligence' : 'Rovo',
							supportLink: (
								<InfoLink href={ATLASSIAN_AI_TROUBLESHOOT_LINK} target={'_blank'}>
									{formatMessage(messages.definitions_support_link_text)}
								</InfoLink>
							),
						}}
					/>
				);
			case NLPSearchErrorState.NoAnswer:
			case NLPSearchErrorState.NoAnswerKeywords:
			case NLPSearchErrorState.NoAnswerSearchResults:
			case NLPSearchErrorState.NoAnswerHydration:
			case NLPSearchErrorState.NoAnswerRelevantContent:
			case NLPSearchErrorState.Default:
			default:
				return (
					<FormattedMessage
						{...messages.definition_no_answer_error}
						values={{
							brand: brand === AiBrand.AI ? 'Atlassian Intelligence' : 'Rovo',
						}}
					/>
				);
		}
	}, [errorState, formatMessage, brand]);

	return (
		<Fragment>
			<Box xcss={ErrorPaddingStyles}>
				<Box xcss={ErrorMessageStyles}>{errorMessage}</Box>
				{showAddDefinitionButton && (
					<Flex justifyContent="end" alignItems="center">
						<Button appearance="default" onClick={() => setShowEditDefinitionModal(true)}>
							{formatMessage(messages.definition_no_answer_add_definition_button)}
						</Button>
					</Flex>
				)}
			</Box>
			<ModalTransition>
				{showEditDefinitionModal && showAddDefinitionButton && (
					<EditDefinitionModal
						curatedDefinitionScopeConfig={definitionCurationOptions.curatedDefinitionScopeConfig}
						closeEditModal={() => setShowEditDefinitionModal(false)}
						isCreateDefinition={true}
					/>
				)}
			</ModalTransition>
		</Fragment>
	);
};
